import React from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import { Link } from 'react-router-dom'
const SetRewardPoints = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main user-member justify-content-sm-between ">
                            <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div className="d-flex align-items-center user-member__title justify-content-center me-sm-25">
                                    <h4 className="text-capitalize fw-500 breadcrumb-title">
                                        Set Reward Points{"   "}
                                    </h4>
                                    <span className="sub-title ms-sm-25 ps-sm-25 ms-2"> Home</span>
                                </div>
                                <form
                                    action="/"
                                    className="d-flex align-items-center user-member__form my-sm-0 my-2"
                                >
                                    <img
                                        src={searchImg}
                                        alt="search"
                                        className="svg"
                                    />
                                    <input
                                        className="form-control me-sm-2 border-0 box-shadow-none"
                                        type="search"
                                        placeholder="Search by Name"
                                        aria-label="Search"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                            <div className="table-responsive">
                                <table className="table mb-0 table-borderless">
                                    <thead>
                                        <tr className="userDatatable-header">
                                            <th>
                                                <span className="userDatatable-title">S.No.</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">Product Name</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">Base Price</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">Points</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="userDatatable-content">
                                                    1.
                                                </div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-content">
                                                    ddddd
                                                </div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-content">
                                                    121212
                                                </div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-content">
                                                    0
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end pt-30 mt-3 mb-3">
                                <nav className="dm-page ">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">Add Price Range Wise</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Points</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="Enter reward points"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Minimum Price</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="Enter waiting days"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Maximum  Price</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="Enter waiting days"
                                                            />
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                                                                cancel
                                                            </button>
                                                            <button className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save Points
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">For All Products</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Points</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="Enter reward points"
                                                            />
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                                                                cancel
                                                            </button>
                                                            <button className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save Points
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetRewardPoints