import React from 'react'

const Configurations = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Deliveryman Configuration</h4>
              <div className="breadcrumb-action justify-content-center flex-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="uil uil-estate" />
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Deliveryman Configuration
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card card-horizontal card-default card-md mb-4">
              <div className="card-header">
                <h6>Deliveryman Configuration</h6>
              </div>
              <div className="card-body row py-md-30">
                <div className="mb-25 select-style2 col-md-12">
                  <div className="card-body py-md-30 d-flex justify-content-between">
                    <label className="form-check-label" for="switch-s1"><strong>Send Mail</strong></label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                    </div>
                  </div>
                </div>
                <div className="mb-25 select-style2 col-md-12">
                  <div className="card-body py-md-30 d-flex justify-content-between">
                    <label className="form-check-label" for="switch-s1"><strong>Monthly Salary</strong></label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                    </div>
                  </div>
                </div>
                <div className="mb-25 select-style2 col-md-12">
                  <div className="card-body py-md-30 d-flex justify-content-between">
                    <label className="form-check-label" for="switch-s1"><strong>Per Order Commission</strong></label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                    </div>
                  </div>
                </div>
                <div className="select-style2 d-flex justify-content-between ps-4 pe-4">
                  <label className="form-check-label" for="switch-s1"><strong>Commission Rate</strong>
                  </label>
                  <input
                    type="text"
                    className="w-50 form-control ih-medium ip-lightradius-xs b-light"
                    id="inputNameIcon1"
                    placeholder="rate"
                  />
                </div>
                <button type="submit" className="ms-4 mt-4 btn btn btn-primary btn-default btn-squared">
                  Update
                </button>
              </div>
            </div>
            {/* ends: .card */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Configurations