import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';
import axios from 'axios';
const CmsView = () => {
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setloading] = useState(false)
    const modules = {
        toolbar: {
            container: [
                [{ header: [2, 3, 4, false] }],
                ["bold", "italic", "underline", "blockquote"],
                [{ color: [] }],
                [{ background: [] }],
                [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                ],
                ["link", "image"],
                ["clean"],
            ],
            // handlers: {
            //     image: imageHandler,
            // },
        },
        clipboard: {
            matchVisual: true,
        },
    }
    useEffect(() => {
        fetchSingleCms();
    }, [])

    // // fetch sub category
    const fetchSingleCms = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/cms/view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("cms", res.data.data)
            setTitle(res.data.data.title)
            setDescription(res.data.data.description)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch cms error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/cmslist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Cms details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                cms Configuration
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Title</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='title'
                                                            value={title}
                                                            disabled
                                                            placeholder="enter main heading"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 col-md-4 mb-3'>
                                                    <ReactQuill disabled theme="snow" modules={modules} value={description}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CmsView;

