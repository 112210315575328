import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import aligncenter from "../../assets/img/svg/align-center-alt.svg"
import eng from "../../assets/img/eng.png"
import ger from "../../assets/img/ger.png"
import iraq from "../../assets/img/iraq.png"
import spa from "../../assets/img/spa.png"
import x from "../../assets/img/svg/x.svg"
import tur from "../../assets/img/tur.png"
import vertical from "../../assets/img/svg/more-vertical.svg"
import flag from "../../assets/img/flag.png"
import author from "../../assets/img/author-nav.jpg"
import ekologo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from './BaseUrl'
const Header = ({ handleToggle }) => {
    const [name, setName] = useState('')
    const [currentImage, setCurrentImage] = useState('')
     const navigate = useNavigate();
    const fetchProfile = async () => {
        try {
            const res = await axios.get(`${baseUrl}/profile-get`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            // console.log("pro", res.data.data)
            setName(res?.data?.data?.full_name)
            setCurrentImage(res?.data?.data?.profile_image)
        } catch (error) {
            console.log("fetch profile error", error)
        }
    }
    const handleLogout = () => {
        localStorage.removeItem("token")
        navigate('/login')
    }
    useEffect(() => {
        fetchProfile();
    }, [fetchProfile])
    return (
        <>
            <div className="mobile-search">
                <form action="" className="search-form">
                    <img src={searchImg} alt="search" className="svg" />
                    <input
                        className="form-control me-sm-2 box-shadow-none"
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                    />
                </form>
            </div>
            <div className="mobile-author-actions" />
            <header className="header-top">
                <nav className="navbar navbar-light">
                    <div className="navbar-left">
                        <div className="logo-area">
                            <Link className="navbar-brand" to="/">
                                <img className="dark" src={ekologo} alt="logo" />
                                <img className="light" src={ekologo} alt="logo" />
                            </Link>
                            <a className="sidebar-toggle" onClick={handleToggle}>
                                <img
                                    className="svg"
                                    src={aligncenter}
                                    alt="img"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="navbar-right">
                        <ul className="navbar-right__menu">
                            <li className="nav-author">
                                <div className="dropdown-custom">
                                    <a href="javascript:;" className="nav-item-toggle">
                                        {
                                            currentImage ? (
                                                <img
                                                    src={`${imgUrl}/${currentImage}`}
                                                    alt=""
                                                    className="rounded-circle"
                                                />
                                            ) : (
                                                <img
                                                    src={author}
                                                    alt=""
                                                    className="rounded-circle"
                                                />
                                            )
                                        }
                                        <span className="nav-item__title">
                                            {name}
                                            <i className="las la-angle-down nav-item__arrow" />
                                        </span>
                                    </a>
                                    <div className="dropdown-parent-wrapper">
                                        <div className="dropdown-wrapper">
                                            <div className="nav-author__info">
                                                <div className="author-img">
                                                    {
                                                        currentImage ? (
                                                            <img
                                                                src={`${imgUrl}/${currentImage}`}
                                                                alt=""
                                                                className="rounded-circle"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={author}
                                                                alt=""
                                                                className="rounded-circle"
                                                            />
                                                        )
                                                    }

                                                </div>
                                                <div>
                                                    <h6>{name}</h6>
                                                    {/* <span>UI Designer</span> */}
                                                </div>
                                            </div>
                                            <div className="nav-author__options">
                                                <ul>
                                                    <li>
                                                        <Link to="/profile">
                                                            <i className="uil uil-user" /> Profile
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/changepassword">
                                                            <i className="uil uil-setting" />
                                                            Change password
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <a type='button' onClick={handleLogout} className="nav-author__signout">
                                                    <i className="uil uil-sign-out-alt" /> Sign Out
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        {/* <div className="navbar-right__mobileAction d-md-none">
                            <a href="#" className="btn-search">
                                <img
                                    src={searchImg}
                                    alt="search"
                                    className="svg feather-search"
                                />
                                <img src={x} alt="x" className="svg feather-x" />
                            </a>
                            <a href="#" className="btn-author-action">
                                <img
                                    className="svg"
                                    src={vertical}
                                    alt="more-vertical"
                                />
                            </a>
                        </div> */}
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header