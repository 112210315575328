import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import baseUrl from '../Helper/BaseUrl'
import { toast } from 'react-toastify'

const AddVariants = () => {
    const [values, setValues] = useState({
        variation_name: "",
        status: "",
    })
    const navigate = useNavigate()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: value,
        }))
    }
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2VjNmE3MWI5YjNkNWFmYWU1ODRjZSIsIm1vYmlsZU51bWJlciI6ODk2NTk1MTE1MSwiZW1haWwiOiJtYW5pc2hAZ2FtaWwuY29tIiwiaWF0IjoxNzMyNjAxMjA2fQ.b7vfdebycvD7dNCAkTQXHOmHOiFvmXiTKaBZWf2jA1U'
    const hanldeSubmit = async (e) => {
        e.preventDefault();
        if (!values.variation_name || !values.status) {
            toast.error("All fields are required!");
            return;
        }
        try {
            const res = await axios.post(`${baseUrl}/variation-store`, values, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log(res)
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/variants')
            }
        } catch (error) {
            console.log("error add variation", error)
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    
                                    / Add New Variants
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                add Variants
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">Add Variants</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form onSubmit={hanldeSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Variants name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name='variation_name'
                                                                value={values.variation_name}
                                                                onChange={handleChange}
                                                                placeholder="Type variation name"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Variation Status</label>
                                                                <select
                                                                    name='status'
                                                                    value={values.status}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">select status</option>

                                                                    <option value="true">Active</option>
                                                                    <option value="false">Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save Variants
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddVariants