import React, { useEffect, useState } from 'react'
import percent from "../../assets/img/svg/percent.svg"
import { Link, useParams } from 'react-router-dom'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
const CouponView = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        coupon_name: "",
        code: "",
        start_date: "",
        end_date: "",
        discount: "",
        description: "",
        status: "",
    })
    // coupon image
    const [imgFile, setImgFiles] = useState(null)
    const [loading, setloading] = useState(false)
    // fetch fetchSingleCoupons api
    const fetchSingleCoupons = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/coupon-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rescat", res.data.data)
            setValues(res.data.data)
            setImgFiles(res?.data?.data?.image)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch coupon error", error)
        }
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    useEffect(() => {
        fetchSingleCoupons();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/couponlist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Coupon Details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Coupon Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='card'>
                            <div className='card-header'></div>
                            <div className='card-body'>
                                <form>
                                    <div className='form-basic'>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name1">Coupon Name</label>
                                                    <input type="text" className="form-control" id="name1" name='coupon_name'
                                                        value={values.coupon_name} disabled placeholder="add coupon name" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">Code</label>
                                                    <input type="text" className="form-control" name='code'
                                                        value={values.code} disabled id="name2" placeholder="add code" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">start Date</label>
                                                    <input type="date" className="form-control" name='start_date'
                                                        value={formatDate(values.start_date)} disabled id="name2" placeholder="start date"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">End Date</label>
                                                    <input type="date" className="form-control" name='end_date'
                                                        value={formatDate(values.end_date)} disabled id="name2" placeholder="end date"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <label htmlFor="name2">Description</label>
                                                    <input type="text" className="form-control" name='description'
                                                        value={values.description} disabled id="name2" placeholder="description" />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group quantity-appearance">
                                                    <label>Discount</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon1" > <img className="svg" src={percent} alt="" />
                                                            </span>
                                                        </div>
                                                        <div className="pt_Quantity">
                                                            <input type="number" className="form-control" name='discount' value={values.discount} disabled // min={0} // max={100} // step={1} // defaultValue={0} // data-inc={1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                <div className="form-group">
                                                    <div className="countryOption">
                                                        <label htmlFor="countryOption">Status</label>
                                                        <select
                                                            name='status'
                                                            value={values.status}
                                                            disabled
                                                            className="form-select"
                                                            id="countryOption"
                                                        >
                                                            <option value="">select status</option>
                                                            <option value="true">Active</option>
                                                            <option value="false">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-md-4 mb-3'>
                                                <h6 className="fw-500">Coupon image</h6>
                                                <div className='upload-part'>

                                                    {imgFile && (
                                                        <div className='upload-part-innr'>
                                                            <img src={`${imgUrl}/${imgFile}`} alt='preview' />
                                                        </div>

                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CouponView
