import axios from 'axios'
import React, { useEffect, useState } from 'react'
import baseUrl from '../Helper/BaseUrl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditUnit = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        unit_name: "",
        status: "",
    })
    const [loading,setLoading] = useState(false);

    const navigate = useNavigate();
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2VjNmE3MWI5YjNkNWFmYWU1ODRjZSIsIm1vYmlsZU51bWJlciI6ODk2NTk1MTE1MSwiZW1haWwiOiJtYW5pc2hAZ2FtaWwuY29tIiwiaWF0IjoxNzMyNjAxMjA2fQ.b7vfdebycvD7dNCAkTQXHOmHOiFvmXiTKaBZWf2jA1U'

    const handleChange = (e)=>{
        const {name,value} = e.target;
        setValues(preVal=>({
            ...preVal,
            [name]:value,
        }))
    }
    // fetch unit details api
    const fetchSingleUnit = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/unit-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setValues(res.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("post unit error", error)
        }
    }

    // unit edit api
    const handleSubmit = async(e)=> {
      e.preventDefault();
      try {
        const res = await axios.post(`${baseUrl}/unit-update/${id}`,values,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        })
         if (res.data.status) {
            toast.success(res.data.message)
            navigate('/units')
         }else{
             toast.error(res.data.message)
         }
      } catch (error) {
        console.log("error edit units",error)
      }
    }

    useEffect(() => {
        fetchSingleUnit();
    },[])
  return (
    <>
          {
              loading && (
                  <div id="overlayer">
                      <div className="loader-overlay">
                          <div className="dm-spin-dots spin-lg">
                              <span className="spin-dot badge-dot dot-primary"></span>
                              <span className="spin-dot badge-dot dot-primary"></span>
                              <span className="spin-dot badge-dot dot-primary"></span>
                              <span className="spin-dot badge-dot dot-primary"></span>
                          </div>
                      </div>
                  </div>
              )}
          <div className="container-fluid">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="shop-breadcrumb">
                          <div className="breadcrumb-main">
                              <h4 className="text-capitalize breadcrumb-title">
                                  <li className="breadcrumb-item ">
                                      <Link to="/units">
                                          <i className="fa-solid fa-circle-arrow-left"></i>
                                          <span>Back</span>
                                      </Link>
                                  </li>
                                  / Edit Units
                              </h4>
                              <div className="breadcrumb-action justify-content-center flex-wrap">
                                  <nav aria-label="breadcrumb">
                                      <ol className="breadcrumb">
                                          <li className="breadcrumb-item">
                                              <Link to="/">
                                                  <i className="uil uil-estate" />
                                                  Home
                                              </Link>
                                          </li>
                                          <li
                                              className="breadcrumb-item active"
                                              aria-current="page"
                                          >
                                             Edit Units
                                          </li>
                                      </ol>
                                  </nav>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                          <div className="row justify-content-center">
                              <div className="col-xxl-7 col-lg-10">
                                  <div className="mx-sm-30 mx-20 ">
                                      <div className="card add-product p-sm-30 p-20 mb-30">
                                          <div className="card-body p-0">
                                              <div className="card-header">
                                                  <h6 className="fw-500">Edit Units</h6>
                                              </div>
                                              <div className="add-product__body px-sm-40 px-20">
                                                  <form onSubmit={handleSubmit}>
                                                      <div className="form-group">
                                                          <label htmlFor="name1">unit name</label>
                                                          <input
                                                              type="text"
                                                              className="form-control"
                                                              id="name1"
                                                              name='unit_name'
                                                              value={values.unit_name}
                                                             onChange={handleChange}
                                                          />
                                                      </div>
                                                      <div className="form-group">
                                                          <div className="countryOption">
                                                              <label htmlFor="countryOption">Status</label>
                                                              <select
                                                                  name='status'
                                                                  value={values.status}
                                                                 onChange={handleChange}
                                                                  className="js-example-basic-single js-states form-control"
                                                                  id="countryOption"
                                                              >
                                                                  <option value="">select status</option>
                                                                  <option value="true">Active</option>
                                                                  <option value="false">inactive</option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                      <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                          <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                              save Units
                                                          </button>
                                                      </div>
                                                  </form>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </>
  )
}

export default EditUnit