import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl from '../Helper/BaseUrl';

const RoleView = () => {
    const {id} = useParams();
    const [values, setValues] = useState({
        role_name: "",
        permissions: [],
    })
    const [permissionList, setPermissionList] = useState([]);
    const [loading,setLoading] = useState(false)
    const fetchPermissions = async () => {
        try {
            const res = await axios.get(`${baseUrl}/permission-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setPermissionList(res.data.data)
        } catch (error) {
            console.log("fetch permission error", error)
        }
    }

    const fetchSingleRole = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/role-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            const permissionIds = res.data.data.permissions.map(permission => permission._id);
            setValues({
                ...res.data.data,
                permissions: permissionIds
            });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("fetch permission error", error)
        }
    }

    useEffect(() => {
        fetchSingleRole();
        fetchPermissions();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/rolelist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Role Details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Role Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">Role Details</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form >
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Role Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name='role_name'
                                                                value={values.role_name}
                                                                disabled
                                                                placeholder="entr role name"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="card card-default card-md mb-4">
                                                                {/* <div className="card-header py-20">
                                                                  <h6>Checkbox Group</h6>
                                                              </div> */}
                                                                <div className="card-body pt-sm-20 pt-3">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="row">
                                                                                {
                                                                                    permissionList.map((curElem, index) => {
                                                                                        return (
                                                                                            <div key={curElem._id} className="col-lg-4">
                                                                                                <div className="checkbox-theme-default custom-checkbox ">
                                                                                                    <input name='permissions'
                                                                                                        // value={values.permissions}
                                                                                                        value={curElem._id}
                                                                                                        // onChange={handleCheckboxChange}
                                                                                                        disabled
                                                                                                        checked={values.permissions.includes(curElem._id)}
                                                                                                        className="checkbox"
                                                                                                        type="checkbox"
                                                                                                        id={`check-${curElem._id}`} />
                                                                                                    <label htmlFor={`check-${curElem._id}`}>
                                                                                                        <span className="checkbox-text">{curElem.permission}</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoleView