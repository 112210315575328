import React from 'react'
import upload from "../../assets/img/svg/upload.svg"
import doller from "../../assets/img/svg/dollar-sign.svg"
import percent from "../../assets/img/svg/percent.svg"
import media from "../../assets/img/food-restaurant-media.png"
import trash from "../../assets/img/svg/trash-2.svg"
const AddDeliveryMen = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="shop-breadcrumb">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">
                  add Delivery Men
                </h4>
                <div className="breadcrumb-action justify-content-center flex-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">
                          <i className="uil uil-estate" />
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        add Delivery Men
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
              <div className="row justify-content-center">
                <div className="col-xxl-7 col-lg-10">
                  <div className="mx-sm-30 mx-20 ">
                    <div className="card add-product p-sm-30 p-20 mb-30">
                      <div className="card-body p-0">
                        <div className="card-header">
                          <h6 className="fw-500">Add Delivery Men</h6>
                        </div>
                        <div className="add-product__body px-sm-40 px-20">
                          <form>
                            <div className="form-group">
                              <label htmlFor="name1">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name1"
                                placeholder="name"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="name2">Email</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name2"
                                placeholder="Email"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="name2">Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name2"
                                placeholder="Phone"
                              />
                            </div>
                            <div className="form-group">
                              <div className="countryOption">
                                <label htmlFor="countryOption">Select User Location</label>
                                <select
                                  className="js-example-basic-single js-states form-control"
                                  id="countryOption"
                                >
                                  <option value="JAN">event</option>
                                  <option value="FBR">Venues</option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="countryOption">
                                <label htmlFor="countryOption">Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name2"
                                  placeholder="Phone"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="name8">Password</label>
                              <input
                                type="text"
                                className="form-control"
                                id="name8"
                                placeholder="Password"
                              />
                            </div>
                            <div className="card add-product p-sm-30 p-20 ">
                              <div className="card-body p-0">
                                <div className="card-header">
                                  <h6 className="fw-500">Delivery Men image</h6>
                                </div>
                                <div className="add-product__body-img px-sm-40 px-20">
                                  <label
                                    htmlFor="upload"
                                    className="file-upload__label"
                                  >
                                    <span className="upload-product-img px-10 d-block">
                                      <span className="file-upload">
                                        <img
                                          className="svg"
                                          src={upload}
                                          alt=""
                                        />
                                        <input
                                          id="upload"
                                          className="file-upload__input"
                                          type="file"
                                          name="file-upload"
                                        />
                                      </span>
                                    </span>
                                  </label>
                                  <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                    <div className="upload-media-area d-flex">
                                      <img
                                        src={media}
                                        alt="img"
                                      />
                                      <div className=" upload-media-area__title  d-flex flex-wrap align-items-center ms-10">
                                        <div>
                                          <p>Deliverymen.jpg</p>
                                          <span>68.8 KB</span>
                                        </div>
                                        <div className="upload-media-area__btn">
                                          <button
                                            type="button"
                                            className="transparent rounded-circle wh-30 border-0 color-danger"
                                          >
                                            <img
                                              className="svg"
                                              src={trash}
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                      <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                        cancel
                      </button>
                      <button className="btn btn-primary btn-default btn-squared text-capitalize">
                        save Delivery Man
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddDeliveryMen