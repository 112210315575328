import React from 'react'

const BulkEmails = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Send Bulk Emails
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="#">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                               Bulk Emails
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        {/* Start: product page */}
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        {/* Start: card */}
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">Send Bulk Email</h6>
                                                </div>
                                                {/* Start: card body */}
                                                <div className="add-product__body px-sm-40 px-20">
                                                    {/* Start: form */}
                                                    <form>
                                                        {/* form group */}
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Email Subject</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="Email Subject"
                                                            />
                                                        </div>
                                                        {/* form group 2 */}
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Subscribers</label>
                                                                <select
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                     <option value="">select subscribers</option>
                                                                    <option value="JAN">Yes</option>
                                                                    <option value="FBR">No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Email Body</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="red chair"
                                                            />
                                                        </div>
                                                    </form>
                                                    {/* End: form */}
                                                </div>
                                                {/* End: card body */}
                                            </div>
                                        </div>
                                        {/* End: card */}
                                        {/* Start: button group */}
                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                            <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                                                cancel
                                            </button>
                                            <button className="btn btn-primary btn-default btn-squared text-capitalize">
                                                send Email
                                            </button>
                                        </div>
                                        {/* End: button group */}
                                    </div>
                                </div>
                                {/* ends: col-lg-8 */}
                            </div>
                        </div>
                        {/* End: Product page */}
                    </div>
                    {/* ends: col-lg-12 */}
                </div>
            </div>
        </>
    )
}

export default BulkEmails