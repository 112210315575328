import React, { useEffect, useState } from 'react'
import baseUrl from '../Helper/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'

const EditFaq = () => {
    const {id} = useParams();
    const [values, setValues] = useState({
        category: "",
        question: "",
        answer: "",
        isVisible: "",
    })

    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }
    const [loading, setloading] = useState(false)
    useEffect(() => {
        fetchSingleFaqs();
    }, [])

    // fetch sub category
    const fetchSingleFaqs = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/faq-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("faq", res.data.data)
            setValues(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch faq error", error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!values.category || !values.question || !values.answer || !values.isVisible) {
            toast.error("All fields are required!");
            return;
        }
        try {

            const res = await axios.post(`${baseUrl}/faq-update/${id}`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("****",res);
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/faqlist')
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("faq post error", error)
        }
    }

    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    add FAQ
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                add FAQ
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Category</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='category'
                                                                value={values?.category}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select category</option>
                                                                <option value="Registration">Registration</option>
                                                                <option value="Purchase">Purchase</option>
                                                                <option value="Payment">Payment</option>
                                                                <option value="Discount Coupons">Discount Coupons</option>
                                                                <option value="Return">Return</option>
                                                                <option value="Feedback">Feedback</option>
                                                                <option value="Technical issues">Technical issues</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">question</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='question'
                                                            value={values?.question}
                                                            onChange={handleChange}
                                                            placeholder="enter question"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">answer</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='answer'
                                                            value={values?.answer}
                                                            onChange={handleChange}
                                                            placeholder="answer"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Status</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='isVisible'
                                                                value={values?.isVisible}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option value="true">isVisible</option>
                                                                <option value="false">notVisible</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save FAQ
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EditFaq


