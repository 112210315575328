import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import doller from "../../assets/img/svg/dollar-sign.svg"
import percent from "../../assets/img/svg/percent.svg"
import media from "../../assets/img/food-restaurant-media.png"
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
const EditProduct = () => {
    const { id } = useParams();
    // console.log("aaja", id)
    const [values, setValues] = useState({
        category_id: "",
        brand_id: "",
        product_name: "",
        price: "",
        is_discount: false,
        discount_price: "",
        in_stock: false,
        stock_qty: "",
        authentic: "",
        is_variation_add: false,
        variation: "",
        description: "",
        sort_description: "",
        status: "",
        sequence: "",
        location: "",
    })
    const [catList, setCatList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [variants, setVariants] = useState([]);
    const [imgFile, setImgFiles] = useState([])
    const [loading, setloading] = useState(false)
    const [states, setStates] = useState([]);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: name === 'in_stock' || name === 'is_variation_add' || name === 'is_discount' ? value === 'true' : value,
        }))
    }
    // category api
    const fetchCategory = async () => {
        try {
            const res = await axios.get(`${baseUrl}/category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rescat", res.data.data)
            setCatList(res.data.data)
        } catch (error) {
            console.log("fetch cat error", error)
        }
    }
    // fetch state api 
    const fetchStates = async () => {
        try {
            const res = await axios.get(`${imgUrl}user/state-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("state", res.data.data)
            setStates(res.data.data)
        } catch (error) {
            console.log("fetch states error", error)
        }
    }
    // // brands api
    // const fetchBrands = async () => {
    //     try {
    //         const res = await axios.get(`${baseUrl}/brand-list`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //         // console.log("brand", res.data.data)
    //         setBrandList(res.data.data)
    //     } catch (error) {
    //         console.log("fetch brand error", error)
    //     }
    // }
    // // vriants api
    // const fetchVariants = async () => {
    //     try {
    //         const res = await axios.get(`${baseUrl}/variation-list`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //         // console.log("var", res.data.data)
    //         setVariants(res.data.data)
    //     } catch (error) {
    //         console.log("fetch variants error", error)
    //     }
    // }

    // product view api
    const fetchSingleProduct = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/product-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })

            // console.log("inner", typeof (res.data.data.in_stock))
            setValues(res.data.data)
            setImgFiles(res.data.data.product_images)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("single product error", error)
        }
    }
    // image file remove
    const handleImageRemove = (index) => {
        setImgFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
    };

    // handle file change
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        console.log(files)
        setImgFiles(prevFiles => [...prevFiles, ...files]);
    };
    // image delete api
    const handleImgDelete = async (imgId) => {
        // console.log("***", imgId)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/product-img-delete/${id}/${imgId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setImgFiles(prevFiles => prevFiles.filter(file => file._id !== imgId));
                    } else {
                        toast.error(res.data.message)
                    }
                    // console.log(res)
                } catch (error) {
                    console.log("error img delete", error)
                }
            }
        })

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("inner121", typeof (values.in_stock))
        // console.log("inner121", imgFile)
        // return
        try {
            const formData = new FormData();
            formData.append('category_id', values.category_id)
            formData.append('brand_id', values.brand_id)
            formData.append('product_name', values.product_name)
            formData.append('price', values.price)
            formData.append('is_discount', values.is_discount)
            formData.append('discount_price', values.discount_price)
            formData.append('in_stock', values.in_stock)
            formData.append('stock_qty', values.stock_qty)
            formData.append('authentic', values.authentic)
            formData.append('is_variation_add', values.is_variation_add)
            formData.append('variation', values.variation)
            formData.append('description', values.description)
            formData.append('sort_description', values.sort_description)
            formData.append('status', values.status)
            formData.append('sequence', values.sequence)
            formData.append('location', values.location)
            if (imgFile && imgFile.length > 0) {
                imgFile.forEach(file => {
                    if (file?.name) {
                        formData.append('product_images', file);
                    }
                });
            }
            const res = await axios.post(`${baseUrl}/product-update/${id}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("edit", res);
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/productlist')
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("product edit error", error)
        }
    }


    useEffect(() => {
        fetchCategory();
        fetchStates();
        fetchSingleProduct();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title d-flex">
                                    <li className="breadcrumb-item ">
                                        <Link to="/productlist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Edit product
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Edit product
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mx-sm-30 mx-20 ">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="add-product__body px-sm-40 px-20">
                                        <form onSubmit={handleSubmit}>
                                            <div className='form-basic'>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">product name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name='product_name'
                                                                value={values.product_name}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="name2">Description</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='description'
                                                                value={values.description}
                                                                id="name2"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="name2">Sort Description</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name='sort_description'
                                                                value={values.sort_description}
                                                                id="name2"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">category</label>
                                                                <select
                                                                    name='category_id'
                                                                    value={values?.category_id}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    {
                                                                        catList.map((cat, index) => {
                                                                            return (
                                                                                <option key={index} value={cat._id}>{cat.name}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">brand</label>
                                                                <select
                                                                    name='brand_id'
                                                                    value={values?.brand_id}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">Select brand</option>
                                                                    {
                                                                        brandList.map((brand, index) => {
                                                                            return (
                                                                                <option key={index} value={brand._id}>{brand.brand_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Authentic</label>
                                                                <select
                                                                    name='authentic'
                                                                    value={values.authentic}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">Select Authentication</option>
                                                                    <option value="true">Active</option>
                                                                    <option value="false">Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">In Stock</label>
                                                                <select
                                                                    name='in_stock'
                                                                    value={values.in_stock}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">Select Stock</option>
                                                                    <option value='true'>Active</option>
                                                                    <option value='false'>Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        values.in_stock === true && (
                                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                                <div className="form-group">
                                                                    <div className="countryOption">
                                                                        <label htmlFor="countryOption">Stock Quantity</label>
                                                                        <input
                                                                            type="number"
                                                                            name='stock_qty'
                                                                            value={values.stock_qty}
                                                                            onChange={handleChange}
                                                                            className="form-control"
                                                                            id="name8"
                                                                            placeholder="enter stock quantity"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Is Variation Add</label>
                                                                <select
                                                                    name='is_variation_add'
                                                                    value={values.is_variation_add}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">Select Variation type</option>
                                                                    <option value='true'>Active</option>
                                                                    <option value='false'>InActive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {
                                                            values.is_variation_add=== true && (
                                                                <div className="form-group">
                                                                    <div className="countryOption">
                                                                        <label htmlFor="countryOption">variants</label>
                                                                        <select
                                                                            name='variation'
                                                                            value={values?.variation}
                                                                            onChange={handleChange}
                                                                            className="js-example-basic-single js-states form-control"
                                                                            id="countryOption"
                                                                        >
                                                                            <option value="">Select variants</option>
                                                                            {
                                                                                variants.map((variant, index) => {
                                                                                    return (
                                                                                        <option key={index} value={variant._id}>{variant.variation_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } */}
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group quantity-appearance">
                                                            <label>price</label>
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        className="input-group-text"
                                                                        id="basic-addon1"
                                                                    >
                                                                        <img
                                                                            className="svg"
                                                                            src={doller}
                                                                            alt=""
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="pt_Quantity">
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name='price'
                                                                        value={values.price}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Is Discount</label>
                                                                <select
                                                                    name='is_discount'
                                                                    value={values.is_discount}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">select Discount</option>
                                                                    <option value='true'>Active</option>
                                                                    <option value='false'>Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        values.is_discount === true && (
                                                            <div className='col-lg-4 col-md-4 mb-3'>
                                                                <div className="form-group quantity-appearance">
                                                                    <label>Discount Price</label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                className="input-group-text"
                                                                                id="basic-addon2"
                                                                            >
                                                                                <img
                                                                                    className="svg"
                                                                                    src={percent}
                                                                                    alt=""
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                        <div className="pt_Quantity">
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                name='discount_price'
                                                                                value={values.discount_price}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <label htmlFor="name8">
                                                                sequence
                                                            </label>
                                                            <input
                                                                type="number"
                                                                name='sequence'
                                                                value={values.sequence}
                                                                className="form-control"
                                                                id="name8"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Status</label>
                                                                <select
                                                                    name='status'
                                                                    value={values.status}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">select status</option>

                                                                    <option value="true">Active</option>
                                                                    <option value="false">Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4 col-md-4 mb-3'>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Location</label>
                                                                <select
                                                                    name='location'
                                                                    value={values.location}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">select state</option>\
                                                                    {
                                                                        states.map((state, index) => {
                                                                            return (
                                                                                <option key={state._id} value={state.name}>{state.name}</option>
                                                                            )
                                                                        })
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-4 mb-3">
                                                        <h6 className="fw-500">Product image</h6>
                                                        <label
                                                            htmlFor="upload"
                                                            className="file-upload__label"
                                                        >
                                                            <span className="upload-product-img px-10 d-block">
                                                                <span className="file-upload">
                                                                    <img
                                                                        className="svg"
                                                                        src={upload}
                                                                        alt=""
                                                                    />
                                                                    <input
                                                                        id="upload"
                                                                        className="file-upload__input"
                                                                        type="file"
                                                                        name="file-upload"
                                                                        multiple
                                                                        onChange={handleImageChange}
                                                                    // onChange={(e) => setCurrentImage(e.target.files[0])}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className="upload-part">
                                                            {
                                                                imgFile.length > 0 ? (
                                                                    imgFile.map((file, index) => {
                                                                        // console.log("123", file);
                                                                        return (
                                                                            file.image ? (
                                                                                <div key={index} className="upload-part-innr">
                                                                                    <img src={`${imgUrl}/${file.image}`} alt={`preview-${index}`} />
                                                                                    <a type='button' onClick={() => handleImgDelete(file._id)}>
                                                                                        <i className='uil uil-times'></i>
                                                                                    </a>
                                                                                </div>
                                                                            ) : (
                                                                                <div key={index} className="upload-part-innr">
                                                                                    <img src={URL.createObjectURL(file)} alt={`preview-${index}`} />
                                                                                    <a type='button' onClick={() => handleImageRemove(index)}>
                                                                                        <i className='uil uil-times-square'></i>
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <img src={media} alt="Default preview" />
                                                                )
                                                            }

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type="submit" className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save product
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditProduct