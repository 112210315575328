import React from 'react'

const ExpireLink = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="d-flex align-items-center user-member__title justify-content-center me-sm-25">
                            <h4 className="text-capitalize fw-500 breadcrumb-title p-5">
                                This link expired
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpireLink