import React, { useEffect, useState } from 'react'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import upload from "../../assets/img/svg/upload.svg"
import { toast } from 'react-toastify'
const EditCategory = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        name: "",
        description: "",
        status: "",
        sequence: "",
    })
    const [imgFile, setImgFile] = useState(null)
    const [loading, setloading] = useState(false)
    const [currentImage, setCurrentImage] = useState("")
    const navigate = useNavigate()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: value
        }))
    }
    // category view api
    const fetchSingleCategory = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/category-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setValues(res.data.data)
            // setImgFile(res.data.data.category_image)
            setCurrentImage(res.data.data.category_image)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("single category error", error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', values.name)
            formData.append('description', values.description)
            formData.append('status', values.status)
            formData.append('sequence', values.sequence)
            if (imgFile) {
                formData.append('category_image', imgFile)
            }
            const res = await axios.post(`${baseUrl}/category-update/${id}`, formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            if (res.data.message) {
                toast.success(res.data.message)
                navigate('/categorylist')
            } else {
                toast.error(res.data.error)
            }
        } catch (error) {
            console.log("error edit category", error)
        }
    }
    useEffect(() => {
        fetchSingleCategory();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/categorylist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Edit category
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Edit category
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">category name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='name'
                                                            value={values?.name}
                                                            onChange={handleChange}
                                                            placeholder="enter category name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">description</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='description'
                                                            value={values?.description}
                                                            onChange={handleChange}
                                                            placeholder="description"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Status</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='status'
                                                                value={values?.status}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name8">sequence</label>
                                                        <input
                                                            type="text"
                                                            name='sequence'
                                                            value={values?.sequence}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="name8"
                                                            placeholder="sequence"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-4 mb-3">

                                                    <h6 className="fw-500">Category image</h6>
                                                    <label
                                                        htmlFor="upload"
                                                        className="file-upload__label"
                                                    >
                                                        <span className="upload-product-img px-10 d-block">
                                                            <span className="file-upload">
                                                                <img
                                                                    className="svg"
                                                                    src={upload}
                                                                    alt=""
                                                                />
                                                                <input
                                                                    id="upload"
                                                                    className="file-upload__input"
                                                                    type="file"
                                                                    name="file-upload"
                                                                    onChange={(e) => setImgFile(e.target.files[0])}
                                                                />
                                                            </span>
                                                        </span>
                                                    </label>

                                                    <div className="upload-media-area">
                                                        {
                                                            imgFile ? (
                                                                <img
                                                                    src={URL.createObjectURL(imgFile)}
                                                                    alt="img"
                                                                />
                                                            ) : (
                                                                currentImage && (
                                                                    <img
                                                                        src={`${imgUrl}/${currentImage}`}
                                                                        alt="Current Category"
                                                                        className="img-fluid"
                                                                    />
                                                                )
                                                            )
                                                        }
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                    save category
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCategory