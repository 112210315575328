import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';

const EditRole = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        role_name: "",
        permissions: [],
    })
    const [permissionList, setPermissionList] = useState([]);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setValues(prevValues => {
            const updatedPermissions = checked
                ? [...prevValues.permissions, value] // Add permission if checked
                : prevValues.permissions.filter(permission => permission !== value); // Remove permission if unchecked

            return {
                ...prevValues,
                permissions: updatedPermissions
            };
        });
    }
    const fetchPermissions = async () => {
        try {
            const res = await axios.get(`${baseUrl}/permission-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(res)
            setPermissionList(res.data.data)
        } catch (error) {
            console.log("fetch permission error", error)
        }
    }

    const fetchSingleRole = async () => {
        try {
            const res = await axios.get(`${baseUrl}/role-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(res)
            const permissionIds = res.data.data.permissions.map(permission => permission._id);
            // setValues(res.data.data)
            setValues({
                ...res.data.data,
                permissions: permissionIds
            });
        } catch (error) {
            console.log("fetch permission error", error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("inner", values)
        try {
            const res = await axios.post(`${baseUrl}/role-update/${id}`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log(res)
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/rolelist')
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            toast.error(error.response)
            console.log("error edit role permission", error)
        }
    }
    useEffect(() => {
        fetchSingleRole();
        fetchPermissions();
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Edit Role
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Edit Role
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="card-header">
                                    <h6 className="fw-500">Edit Role</h6>
                                </div>
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="name1">Role Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name1"
                                                name='role_name'
                                                value={values.role_name}
                                                onChange={handleChange}
                                                placeholder="entr role name"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="card card-default card-md mb-4">
                                                {/* <div className="card-header py-20">
                                                                  <h6>Checkbox Group</h6>
                                                              </div> */}
                                                <div className="card-body pt-sm-20 pt-3">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="row">
                                                                {
                                                                    permissionList.map((curElem, index) => {
                                                                        return (
                                                                            <div key={curElem._id} className="col-lg-4">
                                                                                <div className="checkbox-theme-default custom-checkbox ">
                                                                                    <input name='permissions'
                                                                                        value={curElem._id}
                                                                                        onChange={handleCheckboxChange}
                                                                                        checked={values.permissions.includes(curElem._id)}
                                                                                        className="checkbox"
                                                                                        type="checkbox"
                                                                                        id={`check-${curElem._id}`} />
                                                                                    <label htmlFor={`check-${curElem._id}`}>
                                                                                        <span className="checkbox-text">{curElem.permission}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                            <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                save Role
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditRole