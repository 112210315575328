import React from 'react'

const DeliveryStatusReport = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 mb-30">
                        <div className="card mt-30">
                            <div className="card-body">
                                <div className="userDatatable adv-table-table global-shadow border-light-0 w-100 adv-table">
                                    <div className="table-responsive">
                                        <div className="adv-table-table__header">
                                            <h4>Delivery Status Report</h4>
                                            <div className="adv-table-table__button">
                                                <div className="dropdown">
                                                    <a
                                                        className="btn btn-primary dropdown-toggle dm-select"
                                                        href="#"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        Export
                                                    </a>
                                                    <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="dropdownMenuLink"
                                                    >
                                                        <a className="dropdown-item" href="#">
                                                            copy
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            csv
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            print
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* filter */}
                                        <div
                                            id="filter-form-container"
                                            className="footable-filtering-external footable-filtering-right"
                                        >
                                            <form className="form-inline">
                                                <div className="form-group dm-select d-flex align-items-center adv-table-searchs__status my-xl-25 my-15 mb-0 me-sm-30 me-0">
                                                    <label className="d-flex align-items-center mb-sm-0 mb-2">
                                                        Sort By Prize :
                                                    </label>
                                                    <select className="form-control ms-sm-10 ms-0">
                                                        <option>All</option>
                                                        <option>High</option>
                                                        <option>Low</option>
                                                    </select>
                                                </div>
                                                {/* <div class="form-group form-group-calender">
                                                    <div id="pageWrapper">
                                                        <div id="pageMasthead" class="pageSection"></div>
                                                        <div id="pageContentArea" class="pageSection">
                                                            <form>
                                                                <label for="txtDateRange" class="il-gray fs-14 fw-500 align-center mb-10">Date
                                                                    Range:</label>
                                                                <input type="text" id="txtDateRange" name="txtDateRange" class="inputField shortInputField dateRangeField form-control  ih-medium ip-gray radius-xs b-light" placeholder="01/10/2021 - 01/15/2021" data-from-field="txtDateFrom" data-to-field="txtDateTo" />
                                                                <input type="hidden" id="txtDateFrom" value="" />
                                                                <input type="hidden" id="txtDateTo" value="" />
                                                            </form>
                                                        </div>
                                                        <div id="pageFooter" class="pageSection"></div>
                                                    </div>
                                                </div> */}
                                                <div className="form-group footable-filtering-search">
                                                    <label className="sr-only">Search</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search"
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <table
                                            className="table mb-0 table-borderless adv-table footable footable-1 footable-filtering footable-filtering-right footable-paging footable-paging-right breakpoint-lg"
                                            data-sorting="true"
                                            data-filter-container="#filter-form-container"
                                            data-paging-current={1}
                                            data-paging-position="right"
                                            data-paging-size={10}
                                            style={{}}
                                        >
                                            <thead>
                                                <tr className="userDatatable-header footable-header">
                                                    <th
                                                        className="footable-sortable footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">id</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">Date</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">Total Sales</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">01</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                {/* <a href="#" className="text-dark fw-500"> */}
                                                                <h6>20-12-2024</h6>
                                                                {/* </a> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            121212
                                                        </div>
                                                    </td>

                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr className="footable-paging">
                                                    <td colSpan={8}>
                                                        <div className="footable-pagination-wrapper">
                                                            <ul className="pagination">
                                                                <li
                                                                    className="footable-page-nav disabled"
                                                                    data-page="first"
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        «
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    className="footable-page-nav disabled"
                                                                    data-page="prev"
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        ‹
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    className="footable-page visible active"
                                                                    data-page={1}
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        1
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page visible" data-page={2}>
                                                                    <a className="footable-page-link" href="#">
                                                                        2
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page visible" data-page={3}>
                                                                    <a className="footable-page-link" href="#">
                                                                        3
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page-nav" data-page="next">
                                                                    <a className="footable-page-link" href="#">
                                                                        ›
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page-nav" data-page="last">
                                                                    <a className="footable-page-link" href="#">
                                                                        »
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                            <div className="divider" />
                                                            <span className="label label-default">1 of 3</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryStatusReport