import React from 'react'
import profile2 from "../assets/img/svg/profit2.svg"
import giorgio from "../assets/img/giorgio.png"
import headphone from "../assets/img/headphone.png"
import shoes from "../assets/img/shoes.png"
import mac from "../assets/img/mac-pro.png"
import creative from "../assets/img/creativ-bag.png"
import robert from "../assets/img/author/robert-1.png"
import robert2 from "../assets/img/author/robert-2.png"
import robert3 from "../assets/img/author/robert-3.png"
import robert4 from "../assets/img/author/robert-4.png"
import robert5 from "../assets/img/author/robert-5.png"

const Home = () => {

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-12">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Dashboard</h4>
              <div className="breadcrumb-action justify-content-center flex-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="uil uil-estate" />
                        Dashboard
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          <div className="col-12 mb-25">
            {/* Card 1  */}
            {/* <div className="ap-po-details ap-po-details--3 radius-xl d-flex mb-3">
              <div className="overview-content overview-content3 b-none bg-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-primary color-white rounded-circle">
                    <img
                      src={profile2}
                      alt="img"
                      className="svg"
                    />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Revenue &nbsp; </p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-success fs-14  d-inline-block mt-2">
                        <i className="las la-arrow-up" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview-content overview-content3 bg-none b-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-info color-white rounded-circle">
                    <i className="uil uil-arrow-growth" />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Total Growth{"    "} </p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-success fs-14 d-inline-block mt-2">
                        <i className="las la-arrow-up" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview-content overview-content3 bg-none b-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-success color-white rounded-circle">
                    <i className="uil uil-shopping-cart" />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Orders</p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-success fs-14 d-inline-block mt-2">
                        <i className="las la-arrow-up" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview-content overview-content3 bg-none b-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-warning color-white rounded-circle">
                    <i className="uil uil-usd-circle" />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Sales</p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-danger fs-14 d-inline-block mt-2">
                        <i className="las la-arrow-down" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="ap-po-details ap-po-details--3 radius-xl d-flex mb-3">
              <div className="overview-content overview-content3 b-none bg-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-primary color-white rounded-circle">
                    <img
                      src={profile2}
                      alt="img"
                      className="svg"
                    />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Total visits</p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-success fs-14  d-inline-block mt-2">
                        <i className="las la-arrow-up" />
                        {/* <strong>25.36%</strong> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview-content overview-content3 bg-none b-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-info color-white rounded-circle">
                    <i className="uil uil-arrow-growth" />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Total orders
                      </p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-success fs-14 d-inline-block mt-2">
                        <i className="las la-arrow-up" />
                        {/* <strong>25.36%</strong> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview-content overview-content3 bg-none b-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-success color-white rounded-circle">
                    <i className="uil uil-shopping-cart" />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Progress</p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-success fs-14 d-inline-block mt-2">
                        <i className="las la-arrow-up" />
                        {/* <strong>25.36%</strong> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview-content overview-content3 bg-none b-none">
                <div className="d-flex">
                  <div className="revenue-chart-box__Icon me-20 bg-warning color-white rounded-circle">
                    <i className="uil uil-usd-circle" />
                  </div>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-10">
                      <h1>00</h1>
                      <p className="mt-1 mb-0">Shipped</p>
                    </div>
                    <div className="ap-po-details bg-none">
                      <span className="color-danger fs-14 d-inline-block mt-2">
                        <i className="las la-arrow-down" />
                        {/* <strong>25.36%</strong> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Card 1 End  */}
          </div>
          <div className="col-xxl-4 mb-4">
            <div className="device-chart-box">
              <div className="card border-0">
                <div className="card-header">
                  <h6>Sales Overview</h6>
                  <div className="card-extra">
                    <ul
                      className="card-tab-links nav-tabs nav border-0"
                      role="tablist"
                    >
                      <li>
                        <a
                          className="active"
                          href="#se_device-today"
                          data-bs-toggle="tab"
                          id="se_device-today-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          Today
                        </a>
                      </li>
                      <li>
                        <a
                          href="#se_device-week"
                          data-bs-toggle="tab"
                          id="se_device-week-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Week
                        </a>
                      </li>
                      <li>
                        <a
                          href="#se_device-month"
                          data-bs-toggle="tab"
                          id="se_device-month-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Month
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* ends: .card-header */}
                <div className="card-body pt-20 pb-30">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="se_device-today"
                      role="tabpanel"
                      aria-labelledby="se_device-today-tab"
                    >
                      <div className="device-pieChart-wrap position-relative">
                        <div className="">
                          <div className="salesDonutToday" />
                        </div>
                      </div>
                      <div className="session-wrap session-wrap--top--4">
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-primary" />
                            30%
                          </div>
                        </div>
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-info" />
                            30%
                          </div>
                        </div>
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-warning" />
                            40%
                          </div>
                        </div>
                      </div>
                      <div className="session-wrap session-wrap--2">
                        <div className="session-single">
                          <strong>$7.9k</strong>
                          <div className="chart-label">Revenue</div>
                        </div>
                        <div className="session-single">
                          <strong>50</strong>
                          <div className="chart-label">Sales</div>
                        </div>
                        <div className="session-single">
                          <strong>15</strong>
                          <div className="chart-label">Products</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="se_device-week"
                      role="tabpanel"
                      aria-labelledby="se_device-week-tab"
                    >
                      <div className="device-pieChart-wrap position-relative">
                        <div className="">
                          <div className="salesDonutWeek" />
                        </div>
                      </div>
                      <div className="session-wrap session-wrap--top--4">
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-primary" />
                            30%
                          </div>
                        </div>
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-secondary" />
                            30%
                          </div>
                        </div>
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-warning" />
                            40%
                          </div>
                        </div>
                      </div>
                      <div className="session-wrap session-wrap--2">
                        <div className="session-single">
                          <strong>$7.9k</strong>
                          <div className="chart-label">Revenue</div>
                        </div>
                        <div className="session-single">
                          <strong>50</strong>
                          <div className="chart-label">Sales</div>
                        </div>
                        <div className="session-single">
                          <strong>15</strong>
                          <div className="chart-label">Products</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade "
                      id="se_device-month"
                      role="tabpanel"
                      aria-labelledby="se_device-month-tab"
                    >
                      <div className="device-pieChart-wrap position-relative">
                        <div className="">
                          <div className="salesDonutMonth" />
                        </div>
                      </div>
                      <div className="session-wrap session-wrap--top--4">
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-primary" />
                            30%
                          </div>
                        </div>
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-secondary" />
                            30%
                          </div>
                        </div>
                        <div className="session-single">
                          <div className="chart-label">
                            <span className="label-dot dot-warning" />
                            40%
                          </div>
                        </div>
                      </div>
                      <div className="session-wrap session-wrap--2">
                        <div className="session-single">
                          <strong>$7.9k</strong>
                          <div className="chart-label">Revenue</div>
                        </div>
                        <div className="session-single">
                          <strong>50</strong>
                          <div className="chart-label">Sales</div>
                        </div>
                        <div className="session-single">
                          <strong>15</strong>
                          <div className="chart-label">Products</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends: .session-wrap */}
                </div>
                {/* ends: .card-body */}
              </div>
            </div>
          </div>
          <div className="col-xxl-4 mb-3">
            <div className="card border-0 px-25 pb-15 h-100">
              <div className="card-header">
                <h6>Top Product</h6>
                <div className="card-extra">
                  <ul className="card-tab-links nav-tabs nav border-0" role="tablist">
                    <li>
                      <a
                        className="active"
                        href="#t_selling-today"
                        data-bs-toggle="tab"
                        id="t_selling-today-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Today
                      </a>
                    </li>
                    <li>
                      <a
                        href="#t_selling-week"
                        data-bs-toggle="tab"
                        id="t_selling-week-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Week
                      </a>
                    </li>
                    <li>
                      <a
                        href="#t_selling-month"
                        data-bs-toggle="tab"
                        id="t_selling-month-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Month
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div
                    className="tab-pane fade active show"
                    id="t_selling-today"
                    role="tabpanel"
                    aria-labelledby="t_selling-today-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap__top-product">
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th>Prducts Name</th>
                              <th>Deals</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={giorgio}
                                    alt="img"
                                  />
                                  <span>UV Protected Sunglass</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$38,536</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={headphone}
                                    alt="img"
                                  />
                                  <span>Black Headphone</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$20,573</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={shoes}
                                    alt="img"
                                  />
                                  <span>Nike Shoes</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$17,457</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={mac}
                                    alt="img"
                                  />
                                  <span>15" Mackbook Pro</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$15,354</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={creative}
                                    alt="img"
                                  />
                                  <span>Women Bag</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$12,354</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={shoes}
                                    alt="img"
                                  />
                                  <span>Nike Shoes</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$17,457</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="t_selling-week"
                    role="tabpanel"
                    aria-labelledby="t_selling-week-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap__top-product">
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th>Prducts Name</th>
                              <th>Deals</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={giorgio}
                                    alt="img"
                                  />
                                  <span>UV Protected Sunglass</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$38,536</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={headphone}
                                    alt="img"
                                  />
                                  <span>Black Headphone</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$20,573</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={shoes}
                                    alt="img"
                                  />
                                  <span>Nike Shoes</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$17,457</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={mac}
                                    alt="img"
                                  />
                                  <span>15" Mackbook Pro</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$15,354</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={creative}
                                    alt="img"
                                  />
                                  <span>Women Bag</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$12,354</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={shoes}
                                    alt="img"
                                  />
                                  <span>Nike Shoes</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$17,457</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="t_selling-month"
                    role="tabpanel"
                    aria-labelledby="t_selling-month-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap__top-product">
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th>Prducts Name</th>
                              <th>Deals</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={giorgio}
                                    alt="img"
                                  />
                                  <span>UV Protected Sunglass</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$38,536</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={headphone}
                                    alt="img"
                                  />
                                  <span>Black Headphone</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$20,573</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={shoes}
                                    alt="img"
                                  />
                                  <span>Nike Shoes</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$17,457</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={mac}
                                    alt="img"
                                  />
                                  <span>15" Mackbook Pro</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$15,354</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={creative}
                                    alt="img"
                                  />
                                  <span>Women Bag</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$12,354</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <img
                                    className="radius-xs img-fluid order-bg-opacity-primary"
                                    src={shoes}
                                    alt="img"
                                  />
                                  <span>Nike Shoes</span>
                                </div>
                              </td>
                              <td>1561</td>
                              <td>$17,457</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 mb-3">
            <div className="card border-0 pb-15 h-100">
              <div className="card-header">
                <h6>Latest Transactions</h6>
                <div className="card-extra">
                  <ul className="card-tab-links nav-tabs nav border-0" role="tablist">
                    <li>
                      <a
                        className="active"
                        href="#t_selling-today8"
                        data-bs-toggle="tab"
                        id="t_selling-today8-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Today
                      </a>
                    </li>
                    <li>
                      <a
                        href="#t_selling-week8"
                        data-bs-toggle="tab"
                        id="t_selling-week8-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Week
                      </a>
                    </li>
                    <li>
                      <a
                        href="#t_selling-month8"
                        data-bs-toggle="tab"
                        id="t_selling-month8-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Month
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body py-0">
                <div className="tab-content">
                  <div
                    className="tab-pane fade active show"
                    id="t_selling-today8"
                    role="tabpanel"
                    aria-labelledby="t_selling-today-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap--source selling-table-wrap--transition ">
                      <div className="table-responsive">
                        <table className="table table--default table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-primary-20 color-primary align-items-center">
                                    <i className="uil uil-university" />
                                  </div>
                                  <div>
                                    <h6>Bank Transfer</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-info-20 color-info align-items-center">
                                    <i className="uil uil-paypal" />
                                  </div>
                                  <div>
                                    <h6>Paypal</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-success-20 color-success align-items-center">
                                    <i className="uil uil-wallet" />
                                  </div>
                                  <div>
                                    <h6>Wallet</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-danger">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-warning-20 color-warning align-items-center">
                                    <i className="uil uil-credit-card" />
                                  </div>
                                  <div>
                                    <h6>Credit Card</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-secondary-20 color-secondary align-items-center">
                                    <i className="uil uil-money-bill" />
                                  </div>
                                  <div>
                                    <h6>Transfer</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="t_selling-week8"
                    role="tabpanel"
                    aria-labelledby="t_selling-week8-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap--source selling-table-wrap--transition ">
                      <div className="table-responsive">
                        <table className="table table--default table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-primary-20 color-primary align-items-center">
                                    <i className="uil uil-university" />
                                  </div>
                                  <div>
                                    <h6>Bank Transfer</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-info-20 color-info align-items-center">
                                    <i className="uil uil-paypal" />
                                  </div>
                                  <div>
                                    <h6>Paypal</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-success-20 color-success align-items-center">
                                    <i className="uil uil-wallet" />
                                  </div>
                                  <div>
                                    <h6>Wallet</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-danger">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-warning-20 color-warning align-items-center">
                                    <i className="uil uil-credit-card" />
                                  </div>
                                  <div>
                                    <h6>Credit Card</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-secondary-20 color-secondary align-items-center">
                                    <i className="uil uil-money-bill" />
                                  </div>
                                  <div>
                                    <h6>Transfer</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="t_selling-month8"
                    role="tabpanel"
                    aria-labelledby="t_selling-month8-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap--source selling-table-wrap--transition ">
                      <div className="table-responsive">
                        <table className="table table--default table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-primary-20 color-primary align-items-center">
                                    <i className="uil uil-university" />
                                  </div>
                                  <div>
                                    <h6>Bank Transfer</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-info-20 color-info align-items-center">
                                    <i className="uil uil-paypal" />
                                  </div>
                                  <div>
                                    <h6>Paypal</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-success-20 color-success align-items-center">
                                    <i className="uil uil-wallet" />
                                  </div>
                                  <div>
                                    <h6>Wallet</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-danger">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-warning-20 color-warning align-items-center">
                                    <i className="uil uil-credit-card" />
                                  </div>
                                  <div>
                                    <h6>Credit Card</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper bg-opacity-secondary-20 color-secondary align-items-center">
                                    <i className="uil uil-money-bill" />
                                  </div>
                                  <div>
                                    <h6>Transfer</h6>
                                    <span>10 mins ago</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="color-success">+$573</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="card border-0 px-25 pb-10 h-100">
              <div className="card-header ">
                <h6>Active Users</h6>
                <div className="card-extra">
                  <ul className="card-tab-links nav-tabs nav border-0" role="tablist">
                    <li>
                      <a
                        className="active"
                        href="#t_selling-today222"
                        data-bs-toggle="tab"
                        id="t_selling-today222-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Today
                      </a>
                    </li>
                    <li>
                      <a
                        href="#t_selling-week222"
                        data-bs-toggle="tab"
                        id="t_selling-week222-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Week
                      </a>
                    </li>
                    <li>
                      <a
                        href="#t_selling-month333"
                        data-bs-toggle="tab"
                        id="t_selling-month333-tab"
                        role="tab"
                        aria-selected="true"
                      >
                        Month
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div
                    className="tab-pane fade active show"
                    id="t_selling-today222"
                    role="tabpanel"
                    aria-labelledby="t_selling-today222-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap--source">
                      <div className="table-responsive">
                        <table className="table table--default table-borderless">
                          <thead>
                            <tr>
                              <th>Seller name</th>
                              <th>Company</th>
                              <th>Product</th>
                              <th>Revenue</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-primary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Robert Clinton</span>
                                </div>
                              </td>
                              <td>Samsung</td>
                              <td>Smart Phone</td>
                              <td>$38,536</td>
                              <td><span className="text-success">Done</span></td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-primary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert2}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Michael Johnson </span>
                                </div>
                              </td>
                              <td>Asus</td>
                              <td>Laptop</td>
                              <td>$20,573</td>
                              <td><span className="text-success">Done</span></td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-secondary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert3}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Daniel White</span>
                                </div>
                              </td>
                              <td>Google</td>
                              <td>Watch</td>
                              <td>$17,457</td>
                              <td><span className='text-warning'>Pending</span></td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-success align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert4}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Chris Barin </span>
                                </div>
                              </td>
                              <td>Apple</td>
                              <td>Computer</td>
                              <td>$15,354</td>
                              <td><span className="text-success">Done</span></td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-info align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert5}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Daniel Pink</span>
                                </div>
                              </td>
                              <td>Panasonic</td>
                              <td>Sunglass</td>
                              <td>$12,354</td>
                              <td><span className="text-danger">Cancel</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="t_selling-week222"
                    role="tabpanel"
                    aria-labelledby="t_selling-week222-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap--source">
                      <div className="table-responsive">
                        <table className="table table--default table-borderless">
                          <thead>
                            <tr>
                              <th>Seller name</th>
                              <th>Company</th>
                              <th>Product</th>
                              <th>Revenue</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-primary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Robert Clinton</span>
                                </div>
                              </td>
                              <td>Samsung</td>
                              <td>Smart Phone</td>
                              <td>$38,536</td>
                              <td>Done</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-primary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert2}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Michael Johnson </span>
                                </div>
                              </td>
                              <td>Asus</td>
                              <td>Laptop</td>
                              <td>$20,573</td>
                              <td>Done</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-secondary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert3}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Daniel White</span>
                                </div>
                              </td>
                              <td>Google</td>
                              <td>Watch</td>
                              <td>$17,457</td>
                              <td>Pending</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-success align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert4}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Chris Barin </span>
                                </div>
                              </td>
                              <td>Apple</td>
                              <td>Computer</td>
                              <td>$15,354</td>
                              <td>Done</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-info align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert5}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Daniel Pink</span>
                                </div>
                              </td>
                              <td>Panasonic</td>
                              <td>Sunglass</td>
                              <td>$12,354</td>
                              <td>Done</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="t_selling-month333"
                    role="tabpanel"
                    aria-labelledby="t_selling-month333-tab"
                  >
                    <div className="selling-table-wrap selling-table-wrap--source">
                      <div className="table-responsive">
                        <table className="table table--default table-borderless">
                          <thead>
                            <tr>
                              <th>Seller name</th>
                              <th>Company</th>
                              <th>Product</th>
                              <th>Revenue</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-primary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Robert Clinton</span>
                                </div>
                              </td>
                              <td>Samsung</td>
                              <td>Smart Phone</td>
                              <td>$38,536</td>
                              <td>Done</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-primary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert2}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Michael Johnson </span>
                                </div>
                              </td>
                              <td>Asus</td>
                              <td>Laptop</td>
                              <td>$20,573</td>
                              <td>Done</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-secondary align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert3}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Daniel White</span>
                                </div>
                              </td>
                              <td>Google</td>
                              <td>Watch</td>
                              <td>$17,457</td>
                              <td>Pending</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-success align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert4}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Chris Barin </span>
                                </div>
                              </td>
                              <td>Apple</td>
                              <td>Computer</td>
                              <td>$15,354</td>
                              <td>Done</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="selling-product-img d-flex align-items-center">
                                  <div className="selling-product-img-wrapper order-bg-opacity-info align-items-end">
                                    <img
                                      className=" img-fluid"
                                      src={robert5}
                                      alt="img"
                                    />
                                  </div>
                                  <span>Daniel Pink</span>
                                </div>
                              </td>
                              <td>Panasonic</td>
                              <td>Sunglass</td>
                              <td>$12,354</td>
                              <td>Done</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ends: .row */}
      </div>
    </>

  )
}

export default Home