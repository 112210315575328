import React from 'react'

const PayoutHistory = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="shop-breadcrumb">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">Payout History</h4>
                <div className="breadcrumb-action justify-content-center flex-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">
                          <i className="uil uil-estate" />
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Payout History
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="userDatatable orderDatatable sellerDatatable global-shadow mb-30 py-30 px-sm-30 px-20 radius-xl w-100">
              <div className="row justify-content-center">
                <div className="col-md-12 mt-3">
                  <div className="kb text-center">
                    <div className="kb__header-search" tabindex="0">
                      <form className="kb__header-search-form">
                        <div className="input-group">
                          <div className="kb__select-wrapper border">
                            <select className="kb__select">
                              <option value=" ">Payout Status</option>
                              <option value="kb">Active</option>
                              <option value="post">Inactive</option>
                              <option value="dvfaq">deactive</option>
                            </select>
                          </div>
                          <input type="text" className="form-control ui-autocomplete-input ms-1 " placeholder="Search By Name" name="s" autocomplete="off" />
                          <div className="input-group-append ms-2">
                            <button type="submit" className="btn">Search
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-4">
                <table className="table mb-0 table-borderless border-0">
                  <thead>
                    <tr className="userDatatable-header">
                      <th scope="col">
                        <span className="userDatatable-title">S.No.</span>
                      </th>
                      <th scope="col">
                        <span className="userDatatable-title">Deliveryman</span>
                      </th>
                      <th scope="col">
                        <span className="userDatatable-title">Requested On</span>
                      </th>
                      <th scope="col">
                        <span className="userDatatable-title">Amount</span>
                      </th>
                      <th scope="col">
                        <span className="userDatatable-title">
                          Status
                        </span>
                      </th>
                      <th scope="col" className="text-end">
                        <span className="userDatatable-title ">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="orderDatatable-title">1. </div>
                      </td>
                      <td>
                        <div className="orderDatatable-title">raj</div>
                      </td>
                      <td>
                        <div className="orderDatatable-title">1-1-2010</div>
                      </td>
                      <td>
                        <div className="orderDatatable-title">1</div>
                      </td>
                      <td>
                        <div className="orderDatatable-title">active</div>
                      </td>
                      <td>
                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                          <li>
                            <a href="#" className="edit">
                              <i className="uil uil-edit" />
                            </a>
                          </li>
                          <li>
                            <a href="#" className="remove">
                              <i className="uil uil-trash-alt" />
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mt-15 pt-25 border-top">
                <nav className="dm-page ">
                  <ul className="dm-pagination d-flex">
                    <li className="dm-pagination__item">
                      <a
                        href="#"
                        className="dm-pagination__link pagination-control"
                      >
                        <span className="la la-angle-left" />
                      </a>
                      <a href="#" className="dm-pagination__link">
                        <span className="page-number">1</span>
                      </a>
                      <a href="#" className="dm-pagination__link active">
                        <span className="page-number">2</span>
                      </a>
                      <a href="#" className="dm-pagination__link">
                        <span className="page-number">3</span>
                      </a>
                      <a
                        href="#"
                        className="dm-pagination__link pagination-control"
                      >
                        <span className="page-number">...</span>
                      </a>
                      <a href="#" className="dm-pagination__link">
                        <span className="page-number">12</span>
                      </a>
                      <a
                        href="#"
                        className="dm-pagination__link pagination-control"
                      >
                        <span className="la la-angle-right" />
                      </a>
                      <a href="#" className="dm-pagination__option"></a>
                    </li>
                    <li className="dm-pagination__item">
                      <div className="paging-option">
                        <select name="page-number" className="page-selection">
                          <option value={20}>20/page</option>
                          <option value={40}>40/page</option>
                          <option value={60}>60/page</option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PayoutHistory