import React from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import { Link } from 'react-router-dom'
const ShippingZone = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">Shipping Zone</h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="#">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Shipping Zone
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="userDatatable orderDatatable global-shadow py-30 px-sm-30 px-20 radius-xl w-100 mb-30">
                            <div className="project-top-wrapper d-flex justify-content-between flex-wrap mb-25 mt-n10">
                                <div className="d-flex align-items-center flex-wrap justify-content-center">
                                    <div className="project-search order-search  global-shadow mt-10">
                                        <form action="/" className="order-search__form">
                                            <img
                                                src={searchImg}
                                                alt="search"
                                                className="svg"
                                            />
                                            <input
                                                className="form-control me-sm-2 border-0 box-shadow-none"
                                                type="search"
                                                placeholder="Filter by keyword"
                                                aria-label="Search"
                                            />
                                        </form>
                                    </div>
                                    {/* End: .project-search */}
                                    <div className="project-category d-flex align-items-center ms-md-30 mt-xxl-10 mt-15">
                                        <p className="fs-14 color-gray text-capitalize mb-10 mb-md-0  me-10">
                                            Shipping Zone :
                                        </p>
                                        <div className="project-tap order-project-tap global-shadow">
                                            <ul className="nav px-1" id="ap-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="ap-overview-tab"
                                                        data-bs-toggle="pill"
                                                        href="#ap-overview"
                                                        role="tab"
                                                        aria-selected="true"
                                                    >
                                                        <i className="fa-regular fa-circle-dot"></i>  Zones
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="timeline-tab"
                                                        data-bs-toggle="pill"
                                                        href="#timeline"
                                                        role="tab"
                                                        aria-selected="false"
                                                    >
                                                        <i className="fa-brands fa-get-pocket"></i> Cities
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="activity-tab"
                                                        data-bs-toggle="pill"
                                                        href="#activity"
                                                        role="tab"
                                                        aria-selected="false"
                                                    >
                                                        <i className="fa-brands fa-codepen"></i>  States
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="draft-tab"
                                                        data-bs-toggle="pill"
                                                        href="#draft"
                                                        role="tab"
                                                        aria-selected="false"
                                                    >
                                                        <i className="fa-brands fa-dribbble"></i> Countries
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* End: .project-category */}
                                </div>
                                {/* End: .d-flex */}
                                <div className="content-center mt-10">
                                    <div className="action-btn">
                                        <Link to="/addzone" className="btn px-15 btn-primary">
                                            <i className="fas fa-plus fs-16" />
                                            Add Zone
                                        </Link>
                                    </div>
                                </div>
                                {/* End: .content-center */}
                            </div>
                            {/* End: .project-top-wrapper */}
                            <div className="tab-content" id="ap-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="ap-overview"
                                    role="tabpanel"
                                    aria-labelledby="ap-overview-tab"
                                >
                                    {/* Start Table Responsive */}
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-hover table-borderless border-0">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <div className="d-flex align-items-center">
                                                            <div className="bd-example-indeterminate">
                                                                <div className="checkbox-theme-default custom-checkbox  check-all">
                                                                    <input
                                                                        className="checkbox"
                                                                        type="checkbox"
                                                                        id="check-23e"
                                                                    />
                                                                    <label htmlFor="check-23e">
                                                                        <span className="checkbox-text ms-3">
                                                                            order id
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            Customers
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Amount</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Date
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Actions
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-12"
                                                                            />
                                                                            <label htmlFor="check-grp-12" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-13"
                                                                            />
                                                                            <label htmlFor="check-grp-13" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0004</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Carroll Maharry
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $14,248.61
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 28, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-14"
                                                                            />
                                                                            <label htmlFor="check-grp-14" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0005</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Rosenbaum Reichel
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $24,248.90
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 12, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-4"
                                                                            />
                                                                            <label htmlFor="check-grp-4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0006</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Ashley Briggs
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $6,248.87
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 8, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-5"
                                                                            />
                                                                            <label htmlFor="check-grp-5" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0007</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Carl Jenkins
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $35,545.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 12, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-6"
                                                                            />
                                                                            <label htmlFor="check-grp-6" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0008</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Bertha Martin
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 14, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-7"
                                                                            />
                                                                            <label htmlFor="check-grp-7" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0009</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Stacie Hall
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $34,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 7, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-15"
                                                                            />
                                                                            <label htmlFor="check-grp-15" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0010</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 9, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-9"
                                                                            />
                                                                            <label htmlFor="check-grp-9" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0011</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Amanda Jones
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $14,244.20
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 26, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-10"
                                                                            />
                                                                            <label htmlFor="check-grp-10" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0012</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Ashley Briggs
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 6, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-11"
                                                                            />
                                                                            <label htmlFor="check-grp-11" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0013</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Stacie Hall
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 7, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Table Responsive End */}
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="timeline"
                                    role="tabpanel"
                                    aria-labelledby="timeline-tab"
                                >
                                    {/* Start Table Responsive */}
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-hover table-borderless border-0">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <div className="d-flex align-items-center">
                                                            <div className="bd-example-indeterminate">
                                                                <div className="checkbox-theme-default custom-checkbox  check-all">
                                                                    <input
                                                                        className="checkbox"
                                                                        type="checkbox"
                                                                        id="check-23u"
                                                                    />
                                                                    <label htmlFor="check-23u">
                                                                        <span className="checkbox-text ms-3">
                                                                            order id
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            Customers
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Amount</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Date
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Actions
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-16"
                                                                            />
                                                                            <label htmlFor="check-grp-16" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0001</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 01, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-17"
                                                                            />
                                                                            <label htmlFor="check-grp-17" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0002</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 02, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-18"
                                                                            />
                                                                            <label htmlFor="check-grp-18" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 03, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-19"
                                                                            />
                                                                            <label htmlFor="check-grp-19" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0004</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 04, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-20"
                                                                            />
                                                                            <label htmlFor="check-grp-20" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0005</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 05, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-21"
                                                                            />
                                                                            <label htmlFor="check-grp-21" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0006</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 06, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-22"
                                                                            />
                                                                            <label htmlFor="check-grp-22" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0007</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 07, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-23s"
                                                                            />
                                                                            <label htmlFor="check-grp-23s" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0008</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 08, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-24"
                                                                            />
                                                                            <label htmlFor="check-grp-24" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0009</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 09, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-25"
                                                                            />
                                                                            <label htmlFor="check-grp-25" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0010</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 10, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-26"
                                                                            />
                                                                            <label htmlFor="check-grp-26" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0011</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 11, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-27"
                                                                            />
                                                                            <label htmlFor="check-grp-27" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0012</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 12, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-28"
                                                                            />
                                                                            <label htmlFor="check-grp-28" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0013</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 13, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-29"
                                                                            />
                                                                            <label htmlFor="check-grp-29" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0014</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 14, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-30"
                                                                            />
                                                                            <label htmlFor="check-grp-30" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0015</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 15, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-31"
                                                                            />
                                                                            <label htmlFor="check-grp-31" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0016</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-success  text-success rounded-pill active">
                                                                shiped
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 16, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Table Responsive End */}
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="activity"
                                    role="tabpanel"
                                    aria-labelledby="activity-tab"
                                >
                                    {/* Start Table Responsive */}
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-hover table-borderless border-0">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <div className="d-flex align-items-center">
                                                            <div className="bd-example-indeterminate">
                                                                <div className="checkbox-theme-default custom-checkbox  check-all">
                                                                    <input
                                                                        className="checkbox"
                                                                        type="checkbox"
                                                                        id="check-23"
                                                                    />
                                                                    <label htmlFor="check-23">
                                                                        <span className="checkbox-text ms-3">
                                                                            order id
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            Customers
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Amount</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Date
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Actions
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-s1"
                                                                            />
                                                                            <label htmlFor="check-grp-s1" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-32"
                                                                            />
                                                                            <label htmlFor="check-grp-32" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-33"
                                                                            />
                                                                            <label htmlFor="check-grp-33" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-34"
                                                                            />
                                                                            <label htmlFor="check-grp-34" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-35"
                                                                            />
                                                                            <label htmlFor="check-grp-35" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-36"
                                                                            />
                                                                            <label htmlFor="check-grp-36" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-37"
                                                                            />
                                                                            <label htmlFor="check-grp-37" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-38"
                                                                            />
                                                                            <label htmlFor="check-grp-38" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-39"
                                                                            />
                                                                            <label htmlFor="check-grp-39" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-40"
                                                                            />
                                                                            <label htmlFor="check-grp-40" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-41"
                                                                            />
                                                                            <label htmlFor="check-grp-41" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-42"
                                                                            />
                                                                            <label htmlFor="check-grp-42" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-43"
                                                                            />
                                                                            <label htmlFor="check-grp-43" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-44"
                                                                            />
                                                                            <label htmlFor="check-grp-44" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-45"
                                                                            />
                                                                            <label htmlFor="check-grp-45" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-46"
                                                                            />
                                                                            <label htmlFor="check-grp-46" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-warning  text-warning rounded-pill active">
                                                                Awaiting Shipment
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Table Responsive End */}
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="draft"
                                    role="tabpanel"
                                    aria-labelledby="draft-tab"
                                >
                                    {/* Start Table Responsive */}
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-hover table-borderless border-0">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <div className="d-flex align-items-center">
                                                            <div className="bd-example-indeterminate">
                                                                <div className="checkbox-theme-default custom-checkbox  check-all">
                                                                    <input
                                                                        className="checkbox"
                                                                        type="checkbox"
                                                                        id="check-23c"
                                                                    />
                                                                    <label htmlFor="check-23c">
                                                                        <span className="checkbox-text ms-3">
                                                                            order id
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">
                                                            Customers
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Amount</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Date
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            Actions
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order51"
                                                                            />
                                                                            <label htmlFor="check-grp-order51" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order52"
                                                                            />
                                                                            <label htmlFor="check-grp-order52" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order53"
                                                                            />
                                                                            <label htmlFor="check-grp-order53" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order54"
                                                                            />
                                                                            <label htmlFor="check-grp-order54" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order55"
                                                                            />
                                                                            <label htmlFor="check-grp-order55" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order56"
                                                                            />
                                                                            <label htmlFor="check-grp-order56" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order57"
                                                                            />
                                                                            <label htmlFor="check-grp-order57" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order58"
                                                                            />
                                                                            <label htmlFor="check-grp-order58" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order59"
                                                                            />
                                                                            <label htmlFor="check-grp-order59" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order10"
                                                                            />
                                                                            <label htmlFor="check-grp-order10" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order61"
                                                                            />
                                                                            <label htmlFor="check-grp-order61" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order62"
                                                                            />
                                                                            <label htmlFor="check-grp-order62" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order63"
                                                                            />
                                                                            <label htmlFor="check-grp-order63" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order64"
                                                                            />
                                                                            <label htmlFor="check-grp-order64" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-3 d-flex align-items-center">
                                                                <div className="checkbox-group-wrapper">
                                                                    <div className="checkbox-group d-flex">
                                                                        <div className="checkbox-theme-default custom-checkbox checkbox-group__single d-flex">
                                                                            <input
                                                                                className="checkbox"
                                                                                type="checkbox"
                                                                                id="check-grp-order65"
                                                                            />
                                                                            <label htmlFor="check-grp-order65" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="orderDatatable-title">
                                                                <p className="d-block mb-0">#02-0003</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            Kellie Marquot
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-status d-inline-block">
                                                            <span className="order-bg-opacity-danger  text-danger rounded-pill active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title">
                                                            $4,248.66
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="orderDatatable-title float-end">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                {/* End: tr */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Table Responsive End */}
                                </div>
                            </div>
                            <div className="d-flex justify-content-md-end justify-content-center mt-15 pt-25 border-top">
                                <nav className="dm-page ">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        {/* End: .userDatatable */}
                    </div>
                    {/* End: .col */}
                </div>
            </div>
        </>
    )
}

export default ShippingZone