import React from 'react'

const WalletConfigurations = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                Wallet  Configurations
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="#">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Wallet  Configurations
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">Wallet Configurations</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Enable Wallet Checkout</label>
                                                                <select
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="JAN">disable</option>
                                                                    <option value="FBR">enable</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                                                                cancel
                                                            </button>
                                                            <button className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save Configurations
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WalletConfigurations