import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import axios from 'axios'
import baseUrl from '../Helper/BaseUrl'
import Pagination from '../Helper/Pagination'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const UserList = () => {
    const [userList, setUserList] = useState();
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(1)
    const [searchText, setSearchText] = useState('')
    const itemPerPage = 10;

    useEffect(() => {
        fetchUserList();
    }, [page, searchText])
    const [loading, setloading] = useState(false)
    // variant api
    const fetchUserList = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/user-list?page=${page}&name=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setUserList(res.data.data)
            setTotalItems(res.data.totalCount)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch user error", error)
        }
    }

    const handlePageChange = (pageNumber) => {
        // console.log("333",pageNumber)
        setPage(pageNumber);
    };

    //  handle status change
    const handleStatusToggle = async (userId, currentStatus) => {
        try {
            setloading(true)
            const newStatus = !currentStatus;
            const response = await axios.get(
                `${baseUrl}/user-status-change/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            // console.log(response)
            if (response.data.status) {
                toast.success(response.data.message)
                setloading(false)
                setUserList(prevState =>
                    prevState.map(list =>
                        list._id === userId ? { ...list, status: newStatus } : list
                    )
                );
            }
        } catch (error) {
            setloading(false)
            console.error('Error changing user status:', error);
        }
    };
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">users list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">users list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                        <div className="card-header">
                            <form
                                action=""
                                className="user-member__form"
                            >

                                <div className='with-icon'>
                                    <span className="uil uil-search"></span>
                                    <input
                                        className="form-control"
                                        type="search"
                                        placeholder="Search by Name"
                                        aria-label="Search"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>
                            <div className="card-body">
                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                            <div className="table-responsive">
                                <table className="table mb-0 table-borderless">
                                    <thead>
                                        <tr className="userDatatable-header">
                                            <th>
                                                <span className="userDatatable-title">
                                                    S. No.
                                                </span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">
                                                    user name
                                                </span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">emaill</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">mobile</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">gender</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">country</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">state</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">city</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">status</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title float-end">
                                                    action
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList && userList.map((list, index) => {
                                                return (
                                                    <tr key={list._id}>
                                                        <td>
                                                            <div className="userDatatable-content">
                                                                {++index + 10 * (page - 1)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <div className="userDatatable-inline-title">
                                                                    <h6>{list.full_name}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">
                                                                {list.email}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">{list.mobile}</div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">{list.gender}</div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">{list.country}</div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">{list.state}</div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">{list.city}</div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content d-inline-block">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" checked={list?.status} onChange={() => handleStatusToggle(list._id, list.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                <li>
                                                                    <Link to={`/userdetails/${list._id}`} className="view">
                                                                        <i className="uil uil-eye" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={`/useredit/${list._id}`} className="edit">
                                                                        <i className="uil uil-edit" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="remove">
                                                                        <i className="uil uil-trash-alt" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                currentPage={page}
                                onPageChange={handlePageChange}
                                itemPerPage={itemPerPage}
                                totalItems={totalItems}
                            />
                            {/* <div className="d-flex justify-content-end pt-30">
                                <nav className="dm-page ">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserList