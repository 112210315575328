import React from 'react'
import upload from "../../assets/img/svg/upload.svg"
import media from "../../assets/img/food-restaurant-media.png"
import trash from "../../assets/img/svg/trash-2.svg"
const Advertisement = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Advertisement Configuration
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="#">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Advertisement Configuration
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        {/* Start: product page */}
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        {/* Start: card */}
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500"> Advertisement Configuration</h6>
                                                </div>
                                                {/* Start: card body */}
                                                <div className="add-product__body px-sm-40 px-20">
                                                    {/* Start: form */}
                                                    <form>
                                                        {/* form group */}
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Title</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="title"
                                                            />
                                                        </div>
                                                        {/* form group 1 */}
                                                        <div className="form-group">
                                                            <label htmlFor="name2">Text</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name2"
                                                                placeholder="Text"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name2">Link Text</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name2"
                                                                placeholder="Link Text"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name2">Link</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name2"
                                                                placeholder="Link"
                                                            />
                                                        </div>
                                                        <div className="card add-product p-sm-30 p-20 ">
                                                            <div className="card-body p-0">
                                                                <div className="card-header">
                                                                    <h6 className="fw-500">Banner image</h6>
                                                                </div>
                                                                {/* Start: product body */}
                                                                <div className="add-product__body-img px-sm-40 px-20">
                                                                    <label
                                                                        htmlFor="upload"
                                                                        className="file-upload__label"
                                                                    >
                                                                        <span className="upload-product-img px-10 d-block">
                                                                            <span className="file-upload">
                                                                                <img
                                                                                    className="svg"
                                                                                    src={upload}
                                                                                    alt=""
                                                                                />
                                                                                <input
                                                                                    id="upload"
                                                                                    className="file-upload__input"
                                                                                    type="file"
                                                                                    name="file-upload"
                                                                                />
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                    <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                                        <div className="upload-media-area d-flex">
                                                                            <img
                                                                                src={media}
                                                                                alt="img"
                                                                            />
                                                                            <div className=" upload-media-area__title  d-flex flex-wrap align-items-center ms-10">
                                                                                <div>
                                                                                    <p>Banner_image.jpg</p>
                                                                                    <span>68.8 KB</span>
                                                                                </div>
                                                                                <div className="upload-media-area__btn">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="transparent rounded-circle wh-30 border-0 color-danger"
                                                                                    >
                                                                                        <img
                                                                                            className="svg"
                                                                                            src={trash}
                                                                                            alt=""
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* End: product body */}
                                                            </div>
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                                                                cancel
                                                            </button>
                                                            <button className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save changes
                                                            </button>
                                                        </div>
                                                    </form>
                                                    {/* End: form */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End: button group */}
                                    </div>
                                </div>
                                {/* ends: col-lg-8 */}
                            </div>
                        </div>
                        {/* End: Product page */}
                    </div>
                    {/* ends: col-lg-12 */}
                </div>
            </div>
        </>
    )
}

export default Advertisement