import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const BannerSlider = () => {
    const [bannerList, setBannerList] = useState([]);
    const [loading, setloading] = useState(false)

    useEffect(() => {
        fetchBannerSlider();
    }, [])

    // // fetch sub category
    const fetchBannerSlider = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/hero-slider-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("banner", res.data.data)
            setBannerList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch banner slider error", error)
        }
    }

    // // delete api
    const handleBannerDelete = async (bannerId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/hero-slider-delete/${bannerId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setBannerList(prevBanner => prevBanner.filter(banner => banner._id !== bannerId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete subcategory', error)
                }
            }
        })

    }
    // // handle sub cat status
    const handleToggleStatus = async (bannerId, currentStatus) => {
        try {
            setloading(true)
            const newStatus = !currentStatus;
            const res = await axios.get(`${baseUrl}/hero-slider-status-change/${bannerId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            console.log(res);
            if (res.data.status) {
                toast.success(res.data.message)
                setloading(false)
                setBannerList(prevBanner =>
                    prevBanner.map(banner =>
                        banner._id === bannerId ? { ...banner, status: newStatus } : banner
                    )
                )
            }
        } catch (error) {
            setloading(false)
            console.log("toggle sub category status error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Banner Slider list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Banner Slider list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                        // onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                                <div>
                                    <Link className="btn btn-primary" to="/addbannerslider"><span className="uil uil-plus"></span>Add Banner Slider</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Main heading
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            sub heading
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Button heading
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Slider Link
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    bannerList.map((banner, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{banner.main_heading}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{banner.sub_heading}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{banner.button_heading}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{banner.slider_link}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content d-inline-block">
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" checked={banner.status} onChange={() => handleToggleStatus(banner._id, banner.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/bannersliderview/${banner._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/editbannerslider/${banner._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <a type='button' onClick={() => handleBannerDelete(banner._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerSlider