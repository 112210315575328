import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const FaqList = () => {
    const [faqList, setFaqList] = useState([]);
    const [loading, setloading] = useState(false)

    useEffect(() => {
        fetchFaqlist();
    }, [])

    // fetch sub category
    const fetchFaqlist = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/faq-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("faq", res.data.data)
            setFaqList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch faq error", error)
        }
    }

    // delete api
    const handlefaqDelete = async (faqId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/faq-delete/${faqId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setFaqList(prevfaq => prevfaq.filter(faq => faq._id !== faqId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete subcategory', error)
                }
            }
        })
    }

    // handle sub cat status
    // const handleToggleStatus = async (faqId, currentStatus) => {
    //     try {
    //         setloading(true)
    //         const newStatus = !currentStatus;
    //         const res = await axios.get(`${baseUrl}/faq-status-change/${faqId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("token")}`
    //                 }
    //             })
    //         console.log(res);
    //         if (res.data.status) {
    //             toast.success(res.data.message)
    //             setloading(false)
    //             setfaqList(prevfaq =>
    //                 prevfaq.map(faq =>
    //                     faq._id === faqId ? { ...faq, status: newStatus } : faq
    //                 )
    //             )
    //         }
    //     } catch (error) {
    //         setloading(false)
    //         console.log("toggle sub category status error", error)
    //     }
    // }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">FAQ list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">faq list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >
                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                        // onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                                <div>
                                    <Link className="btn btn-primary" to="/addfaq">
                                        <span className="uil uil-plus"></span>
                                        Add Faq list</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Questions
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Answers
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Category
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    faqList.map((faq, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{faq.question}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{faq.answer}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{faq.category}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/faqview/${faq._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/editfaq/${faq._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <a type='button' onClick={() => handlefaqDelete(faq?._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqList
