import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
const ProductList = () => {
    const [productList, setProductList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('')
    useEffect(() => {
        fetchProduct();
    }, [searchText])
    const fetchProduct = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/product-list?name=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("product", res.data.data)
            setProductList(res.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("fetch product error", error)
        }
    }

    // delete api
    const handleProductDelete = async (proId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/product-delete/${proId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setProductList(prevPro => prevPro.filter(pro => pro._id !== proId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete subcategory', error)
                }
            }
        })

    }
    //  handle status change
    const handleAuthenticToggle = async (productId, currentStatus) => {
        try {
            setLoading(true)
            const newAuthentic = !currentStatus;
            const response = await axios.post(
                `${baseUrl}/product-authentic-change/${productId}`,
                { status: newAuthentic },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            if (response.data.status) {
                toast.success(response.data.message)
                setLoading(false)
                setProductList(prevState =>
                    prevState.map(prod =>
                        prod._id === productId ? { ...prod, authentic: newAuthentic } : prod
                    )
                );
            }
        } catch (error) {
            setLoading(false)
            console.error('Error changing product status:', error);
        }
    };
    //  handle status change
    const handleStatusToggle = async (productId, currentStatus) => {
        try {
            setLoading(true)
            const newStatus = !currentStatus;
            const response = await axios.post(
                `${baseUrl}/product-status-change/${productId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            if (response.data.status) {
                toast.success(response.data.message)
                setLoading(false)
                setProductList(prevState =>
                    prevState.map(prod =>
                        prod._id === productId ? { ...prod, status: newStatus } : prod
                    )
                );
            }
        } catch (error) {
            setLoading(false)
            console.error('Error changing product status:', error);
        }
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Product list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Product list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                                <div>
                                    <Link className="btn btn-primary" to="/addproduct"><span className="uil uil-plus"></span>Add Product</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    loading ? (
                                        <div id="overlayer">
                                            <div className="loader-overlay">
                                                <div className="dm-spin-dots spin-lg">
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                    <span className="spin-dot badge-dot dot-primary"></span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                            <div className="table-responsive">
                                                <table className="table mb-0 table-borderless">
                                                    <thead>
                                                        <tr className="userDatatable-header">
                                                            <th><span className="userDatatable-title">S.No.</span></th>
                                                            <th>
                                                                <span className="userDatatable-title">image</span>
                                                            </th>
                                                            <th>
                                                                <span className="checkbox-text userDatatable-title">
                                                                    Product title
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">categories</span>
                                                            </th>
                                                            {/* <th>
                                                        <span className="userDatatable-title">Brand</span>
                                                    </th> */}
                                                            {/* <th>
                                                        <span className="userDatatable-title">Variation</span>
                                                    </th> */}
                                                            <th>
                                                                <span className="userDatatable-title">Price</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Stock</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Sequence</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Location</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">Authentic</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title">status</span>
                                                            </th>
                                                            <th>
                                                                <span className="userDatatable-title float-end">
                                                                    action
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            productList.map((prod, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="userDatatable-content">{index + 1}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                <img src={`${imgUrl}${prod?.product_images[0]?.image}`} className="profile-image rounded-circle d-block m-0 wh-38" />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-inline-title">

                                                                                <h6>{prod?.product_name}</h6>

                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">
                                                                                {prod?.category_id?.name}
                                                                            </div>
                                                                        </td>
                                                                        {/* <td>
                                                                    <div className="userDatatable-content">{prod?.brand_id?.brand_name}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">{prod?.variation?.variation_name}</div>
                                                                </td> */}
                                                                        <td>
                                                                            <div className="userDatatable-content">{prod?.price}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">{prod?.stock_qty}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">{prod?.sequence}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content">{prod?.location}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content d-inline-block">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" checked={prod?.authentic} onChange={() => handleAuthenticToggle(prod._id, prod.authentic)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="userDatatable-content d-inline-block">
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" checked={prod?.status} onChange={() => handleStatusToggle(prod._id, prod.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                                <li>
                                                                                    <Link to={`/productview/${prod?._id}`} className="view">
                                                                                        <i className="uil uil-eye" />
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link to={`/editproduct/${prod?._id}`} className="edit">
                                                                                        <i className="uil uil-edit" />
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <a type='button' onClick={() => handleProductDelete(prod?._id)} className="remove">
                                                                                        <i className="uil uil-trash-alt" />
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* <div className="d-flex justify-content-end pt-30">
                                        <nav className="dm-page ">
                                            <ul className="dm-pagination d-flex">
                                                <li className="dm-pagination__item">
                                                    <a
                                                        href="#"
                                                        className="dm-pagination__link pagination-control"
                                                    >
                                                        <span className="la la-angle-left" />
                                                    </a>
                                                    <a href="#" className="dm-pagination__link">
                                                        <span className="page-number">1</span>
                                                    </a>
                                                    <a href="#" className="dm-pagination__link active">
                                                        <span className="page-number">2</span>
                                                    </a>
                                                    <a href="#" className="dm-pagination__link">
                                                        <span className="page-number">3</span>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="dm-pagination__link pagination-control"
                                                    >
                                                        <span className="page-number">...</span>
                                                    </a>
                                                    <a href="#" className="dm-pagination__link">
                                                        <span className="page-number">12</span>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="dm-pagination__link pagination-control"
                                                    >
                                                        <span className="la la-angle-right" />
                                                    </a>
                                                    <a href="#" className="dm-pagination__option"></a>
                                                </li>
                                                <li className="dm-pagination__item">
                                                    <div className="paging-option">
                                                        <select name="page-number" className="page-selection">
                                                            <option value={20}>20/page</option>
                                                            <option value={40}>40/page</option>
                                                            <option value={60}>60/page</option>
                                                        </select>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div> */}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductList