import React from 'react'

const DeliverMenPayroll = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Payroll</h4>
              <div className="breadcrumb-action justify-content-center flex-wrap">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="uil uil-estate" />
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Payroll
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-horizontal card-default card-md mb-4">
              <div className="card-header">
                <h6>Payroll</h6>
              </div>
              <div className="card-body row py-md-30">
                <div className="mb-25 select-style2 col-md-5">
                  <div className="dm-select ">
                    <select
                      name="select-alerts2"
                      id="select-alerts2"
                      className="form-control "
                    >
                      <option value="{01}">select deliveryman</option>
                      <option value="{02}">Option 2</option>
                      <option value="{03}">Option 3</option>
                      <option value="{04}">Option 4</option>
                      <option value="{05}">Option 5</option>
                    </select>
                  </div>
                </div>
                <div className="select-style2 col-md-5">
                  <div className="dm-select ">
                    <select
                      name="select-option2"
                      id="select-option2"
                      className="form-control "
                    >
                      <option value="{01}">Select month</option>
                      <option value="{02}">Option 2</option>
                      <option value="{03}">Option 3</option>
                      <option value="{04}">Option 4</option>
                      <option value="05}">Option 5</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2">
                  <button type="submit" className="btn btn btn-primary btn-default btn-squared btn-transparent-primary ">
                    Generate
                  </button>
                </div>
              </div>
            </div>
            {/* ends: .card */}
          </div>
          <div className="col-lg-12">
            <div className="card card-horizontal card-default card-md mb-4">
              <div className="card-header">
                <h6>salary info</h6>
              </div>
              <div className="card-body row py-md-30">
                <div className="mb-25 select-style2 col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    id="name8"
                    placeholder="Basic salary"
                  />
                </div>
                <div className="select-style2 col-md-6">
                  <div className="dm-select ">
                    <select
                      name="select-option2"
                      id="select-option2"
                      className="form-control "
                    >
                      <option value="{01}">Select Status</option>
                      <option value="{02}">pending</option>
                      <option value="{03}">confirm</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* ends: .card */}
          </div>

          <div className="col-lg-6">
            <div className="card card-default card-md mb-4">
              <div className="card-header">
                <h6>Allowances</h6>
                <button type="submit" className="btn btn btn-primary btn-default btn-squared btn-transparent-primary ">
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>
              <div className="card-body py-md-30">
                <form action="#">
                  <div className="form-group row mb-n25">
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-user lar color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon1"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-lock las color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon4"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="las la-credit-card color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon5"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-phone las color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon6"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card card-default card-md mb-4">
              <div className="card-header">
                <h6>deduction</h6>
                <button type="submit" className="btn btn btn-primary btn-default btn-squared btn-transparent-primary ">
                  <i className="fa-solid fa-minus"></i>
                </button>
              </div>
              <div className="card-body py-md-30">
                <form action="#">
                  <div className="form-group row mb-n25">
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-user lar color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon1"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-lock las color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon4"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="las la-credit-card color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon5"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-phone las color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon6"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card card-default card-md mb-4">
              <div className="card-header">
                <h6>Gross salary</h6>
              </div>
              <div className="card-body py-md-30">
                <form action="#">
                  <div className="form-group row mb-n25">
                    <div className="col-md-12 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-user lar color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon1"
                          placeholder="Total allowances"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="la-lock las color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon4"
                          placeholder="Total deductions"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-25 mb-3">
                      <div className="with-icon">
                        <span className="las la-credit-card color-light" />
                        <input
                          type="text"
                          className="form-control  ih-medium ip-lightradius-xs b-light"
                          id="inputNameIcon5"
                          placeholder="Net salary"
                        />
                      </div>
                    </div>
                    <button type="submit" className="ms-3 btn btn btn-primary btn-default btn-squared">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default DeliverMenPayroll