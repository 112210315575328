import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import baseUrl from '../Helper/BaseUrl';

const ProtectedRoute = ({ component: Component, requiredPermission }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [userPermissions, setUserPermissions] = useState([]);
    // const [hasPermission,setHasPermission] = useState(false)
    const customId = "custom-id-yes";
    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem("token");
            // console.log('token',token)
            if (!token) {
                navigate("/login", { replace: true });
                return;
            }
            try {
                const response = await axios.get(`${baseUrl}/profile-get`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                // Get user permissions
                // const permissionsResponse = await axios.get(`${baseUrl}/admin-role-permissions`, {
                //     headers: { Authorization: `Bearer ${token}` }
                // });
                // console.log("perm",permissionsResponse)
                // if (permissionsResponse.data.status) {
                //     const permissions = permissionsResponse.data.data.permissions;
                //     setUserPermissions(permissions);

                //     // Check if user has required permission
                //     if (requiredPermission && !permissions.includes(requiredPermission)) {
                //         toast.error("You don't have permission to access this page", {
                //             toastId: customId
                //         });
                //         // navigate("/pagenotforund");
                //         return;
                //     }
                //     setHasPermission(true);
                // }
                setIsAuthenticated(true);
            } catch (error) {
                toast.warning("Please Login First. Token has expired.", {
                    toastId: customId
                });
                localStorage.clear();
                navigate("/login");
            }
        };
        validateToken();
    }, [navigate]);

    if (!isAuthenticated) {
        return null; // or a loading spinner
    }

    return  <Component /> ;
};

export default ProtectedRoute;