import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
const Order = () => {
    const [orderList, setOrderList] = useState([]);
    const [loading, setloading] = useState(false)
    const fetchOrders = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/order-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res.data.data)
            setOrderList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("error fetch orders", error)
        }
    }

    // delete api
    const handleOrderDelete = async (catId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const res = await axios.delete(`${baseUrl}/order-delete/${catId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setOrderList(prevCat => prevCat.filter(cat => cat._id !== catId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete order', error)
                }
            }
        })

    }
    useEffect(() => {
        fetchOrders();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Order list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Order list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-block py-3">
                                <form action="" className="">
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <label>Search</label>
                                            <input className="form-control" type="search" placeholder="Search by Name" aria-label="Search" />
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>Status</label>
                                            <select className='form-select'>
                                                <option>Select Status</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-3'>
                                            <label>Date</label>
                                            <input type='date' className='form-control' />
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="card-body">

                                <div className=" border-light-0 p-30 bg-white radius-xl w-100 mb-30">

                                    <div class="table-responsive">
                                        <table class="table mb-0 table-striped">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <span class="userDatatable-title">
                                                            order id
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span class="userDatatable-title">
                                                            Customers
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span class="userDatatable-title">Status</span>
                                                    </th>
                                                    <th>
                                                        <span class="userDatatable-title">Amount</span>
                                                    </th>
                                                    <th>
                                                        <span class="userDatatable-title">
                                                            Date
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span class="userDatatable-title">
                                                            Actions
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderList && orderList.map((list, index) => {
                                                        return (
                                                            <tr key={list._id}>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {index + 1}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {list.user_id}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        <span className="order-bg-opacity-success  text-success rounded-pill active p-1">
                                                                            {list.status}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        {list.total_amount}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content">
                                                                        January 20, 2020
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                                        <li>
                                                                            <Link to={`/orderview/${list._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <a type='button' onClick={() => handleOrderDelete(list?._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Order;