import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import doller from "../../assets/img/svg/dollar-sign.svg"
import percent from "../../assets/img/svg/percent.svg"
import media from "../../assets/img/food-restaurant-media.png"
import trash from "../../assets/img/svg/trash-2.svg"
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
const AddProduct = () => {
  const [values, setValues] = useState({
    category_id: "",
    brand_id: "",
    product_name: "",
    price: "",
    is_discount: false,
    discount_price: "",
    in_stock: false,
    stock_qty: "",
    authentic: "",
    is_variation_add: false,
    variation: "",
    description: "",
    sort_description: "",
    status: "",
    sequence: "",
    reviewRating: "",
    location:"",
  })
  const [catList, setCatList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [variants, setVariants] = useState([]);
  const [states, setStates] = useState([]);
  // product_images
  const [imgFile, setImgFiles] = useState([])
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  }
  // category api
  const fetchCategory = async () => {
    try {
      const res = await axios.get(`${baseUrl}/category-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      // console.log("rescat", res.data.data)
      setCatList(res.data.data)
    } catch (error) {
      console.log("fetch cat error", error)
    }
  }
  // // brands api
  // const fetchBrands = async () => {
  //   try {
  //     const res = await axios.get(`${baseUrl}/brand-list`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     })
  //     // console.log("brand", res.data.data)
  //     setBrandList(res.data.data)
  //   } catch (error) {
  //     console.log("fetch brand error", error)
  //   }
  // }
  // // vriants api
  // const fetchVariants = async () => {
  //   try {
  //     const res = await axios.get(`${baseUrl}/variation-list`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     })
  //     // console.log("var", res.data.data)
  //     setVariants(res.data.data)
  //   } catch (error) {
  //     console.log("fetch variants error", error)
  //   }
  // }

  // state list api
  const fetchStates = async () => {
    try {
      const res = await axios.get(`${imgUrl}user/state-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      // console.log("state", res.data.data)
      setStates(res.data.data)
    } catch (error) {
      console.log("fetch states error", error)
    }
  }

  useEffect(() => {
    fetchCategory();
     fetchStates();
    // fetchVariants();
  }, [])
  // handle post api
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!values.category_id || !values.product_name || !values.price ||
      !values.authentic || !values.description || !values.status || !values.sequence || !values.location
    ) {
      toast.error("All fields are required!");
      return;
    }
    if (!values.is_discount) {
      toast.error('Discount status is required')
    } else if (values.is_discount === 'true' && !values.discount_price) {
      toast.error('Discount price is required')
    }
    if (!values.in_stock) {
      toast.error('stock status is required')
    } else if (values.in_stock === 'true' && !values.stock_qty) {
      toast.error('stock quantity is required')
    }
    // if (!values.is_variation_add) {
    //   toast.error('variation status is required')
    // } else if (values.is_variation_add === 'true' && !values.variation) {
    //   toast.error('variation is required')
    // }
    if (imgFile.length === 0) {
      toast.error("Please upload at least one image.");
      return;
    }
    if (!imgFile) {
      toast.error("Please upload an image.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append('category_id', values.category_id)
      // formData.append('brand_id', values.brand_id)
      formData.append('product_name', values.product_name)
      formData.append('price', values.price)
      formData.append('is_discount', values.is_discount)
      if (values.is_discount === 'true') {
        formData.append('discount_price', values.discount_price)
      }
      formData.append('in_stock', values.in_stock)
      if (values.in_stock === 'true') {
        formData.append('stock_qty', values.stock_qty)
      }
      formData.append('authentic', values.authentic)
      // formData.append('is_variation_add', values.is_variation_add)
      // if (values.is_variation_add === 'true') {
      //   formData.append('variation', values.variation)
      // }
      formData.append('description', values.description)
      formData.append('sort_description', values.sort_description)
      formData.append('status', values.status)
      formData.append('sequence', values.sequence)
      formData.append('location', values.location)
      // if (imgFile) {
      //   formData.append('product_images', imgFile)
      // }
      imgFile.forEach(file => {
        formData.append('product_images', file); // Append each image
      });
      const res = await axios.post(`${baseUrl}/product-store`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      // console.log("****",res);
      if (res.data.status) {
        toast.success(res.data.message)
        navigate('/productlist')
      } else {
        toast.error(res.data.message)
      }

    } catch (error) {
      console.log("product post error", error)
    }
  }

  // image file remove
  const handleImageRemove = (index) => {
    setImgFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
  };
  // handle file change
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    setImgFiles(prevFiles => [...prevFiles, ...files]); // Add new files to existing ones
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="shop-breadcrumb">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">
                  add product
                </h4>
                <div className="breadcrumb-action justify-content-center flex-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="uil uil-estate" />
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        add product
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className='card'>
              <div className='card-header'></div>
              <div className='card-body'>
                <form onSubmit={handleSubmit}>
                  <div className='form-basic'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name1">Product Name</label>
                          <input type="text" className="form-control" id="name1" name='product_name' value={values.product_name} onChange={handleChange} placeholder="add product name" />
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name2">Description</label>
                          <input type="text" className="form-control" name='description' value={values.description} onChange={handleChange} id="name2" placeholder="description" />
                        </div>
                      </div>

                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name2">Sort Description</label>
                          <input type="text" className="form-control" name='sort_description' value={values.sort_description} onChange={handleChange} id="name2" placeholder="sort description"
                          />
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">category</label>
                            <select name='category_id' value={values.category_id} onChange={handleChange} className="form-select" id="countryOption" >
                              <option value="">Select Category</option>
                              {
                                catList.map((cat, index) => {
                                  return (
                                    <option key={index} value={cat._id}>{cat.name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">brand</label>
                            <select name='brand_id' value={values.brand_id} onChange={handleChange} className="form-select" id="countryOption" >
                              <option value="">Select brand</option>
                              {
                                brandList.map((brand, index) => {
                                  return (
                                    <option key={index} value={brand._id}>{brand.brand_name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div> */}
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Authentic</label>
                            <select name='authentic' value={values.authentic} onChange={handleChange} className="form-select" id="countryOption"
                            >
                              <option value="">Select Authentication</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">In Stock</label>
                            <select
                              name='in_stock'
                              value={values.in_stock}
                              onChange={handleChange}
                              className="form-select"
                              id="countryOption"
                            >
                              <option value="">Select Stock</option>
                              <option value={true}>In Stock</option>
                              <option value={false}>Out of Stock</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {
                        values.in_stock === 'true' && (
                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group">
                              <div className="countryOption">
                                <label htmlFor="countryOption">Stock Quantity</label>
                                <input
                                  type="number"
                                  name='stock_qty'
                                  value={values.stock_qty}
                                  onChange={handleChange}
                                  className="form-control"
                                  id="name8"
                                  placeholder="enter stock quantity"
                                />
                              </div>
                            </div>
                          </div>
                        )
                      }

                      {/* <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Is Variation Add</label>
                            <select
                              name='is_variation_add'
                              value={values.is_variation_add}
                              onChange={handleChange}
                              className="form-select"
                              id="countryOption"
                            >
                              <option value="">Select Variation type</option>
                              <option value={true}>Active</option>
                              <option value={false}>InActive</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {
                        values.is_variation_add === 'true' && (
                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group">
                              <div className="countryOption">
                                <label htmlFor="countryOption">variants</label>
                                <select name='variation' value={values.variation} onChange={handleChange} className="form-select" id="countryOption" >
                                  <option value="">Select variants</option>
                                  {
                                    variants.map((variant, index) => {
                                      return (
                                        <option key={index} value={variant._id}>{variant.variation_name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        )

                      } */}

                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group quantity-appearance">
                          <label>price</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="basic-addon1" > <img className="svg" src={doller} alt="" />
                              </span>
                            </div>
                            <div className="pt_Quantity">
                              <input type="number" className="form-control" name='price' value={values.price} onChange={handleChange} // min={0} // max={100} // step={1} // defaultValue={0} // data-inc={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Is Discount</label>
                            <select name='is_discount' value={values.is_discount} onChange={handleChange} className="form-select" id="countryOption" >
                              <option value="">select Discount</option>
                              <option value={true}>Active</option>
                              <option value={false}>Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {

                        values.is_discount === 'true' && (
                          <div className='col-lg-4 col-md-4 mb-3'>
                            <div className="form-group quantity-appearance">
                              <label>Discount Price</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text" id="basic-addon2" > <img className="svg" src={percent} alt="" />
                                  </span>
                                </div>
                                <div className="pt_Quantity">
                                  <input type="number" className="form-control" name='discount_price' value={values.discount_price} onChange={handleChange} // min={0} // max={100} // step={1} // defaultValue={0} // data-inc={1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )

                      }

                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <label htmlFor="name8"> sequence </label>
                          <input type="number" name='sequence' value={values.sequence} onChange={handleChange} className="form-control" id="name8" placeholder="enter sequence" />
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">State</label>
                            <select
                              name='location'
                              value={values.location}
                              onChange={handleChange}
                              className="form-select"
                              id="countryOption"
                            >
                              <option value="">select location</option>
                              {
                                states.map((state,index)=>{
                                  return (
                                    <option key={state._id} value={state.name}>{state.name}</option>
                                  )
                                })
                              }
                            
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 mb-3'>
                        <div className="form-group">
                          <div className="countryOption">
                            <label htmlFor="countryOption">Status</label>
                            <select
                              name='status'
                              value={values.status}
                              onChange={handleChange}
                              className="form-select"
                              id="countryOption"
                            >
                              <option value="">select status</option>
                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12 col-md-4 mb-3'>
                        <h6 className="fw-500">Product image</h6>
                        <label
                          htmlFor="upload"
                          className="file-upload__label"
                        >
                          <span className="upload-product-img px-10 d-block">
                            <span className="file-upload">
                              <img className="svg" src={upload} alt="" />
                              <input id="upload" className="file-upload__input" type="file" name="file-upload" multiple onChange={handleImageChange}
                              />
                            </span>
                          </span>
                        </label>
                        <div className='upload-part'>

                          {imgFile.length > 0 && (
                            imgFile.map((file, index) => (
                              <div className='upload-part-innr'>
                                <img src={URL.createObjectURL(file)} alt={`preview-${index}`} />
                                <a type="button" onClick={() => handleImageRemove(index)} > <i className='uil uil-times'></i> </a>
                              </div>
                            )))}

                        </div>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                          save product
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default AddProduct