import React, { useState } from 'react'
import ekologo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
const Login = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [values,setValues] = useState({
        email:"",
        password:"",
    })
    const [showPassword,setShowPassword] = useState(false)
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const validateLogin = () => {
        const { email, password } = values;
        if (!email?.trim()) {
            toast.error('Please Enter valid email');
            return false;
        }
        if (!emailRegex.test(email)) {
            toast.error('Please enter a valid email address');
            return false;
        }
        if (!password) {
            toast.error('Please Enter Password');
            return false;
        }
        return true;
    }
    // console.log(values)
    const handleSubmit = async(e) => {
      e.preventDefault();
      if (validateLogin()) {
        setLoading(true)
          try {
              const res = await axios.post(`${baseUrl}/login`, values)
            //   console.log("login", res)
              console.log(res)
              if (res.data.status) {  
                  localStorage.setItem("token",res.data.token)
                  toast.success(res.data.message)
                  navigate('/')
                  
                  setValues({
                      email: "",
                      password: "",
                  });
                  setLoading(false)
              } else {
                  setLoading(false)
                  toast.error(res.data.message || res.data.msg)
              }
          } catch (error) {
              setLoading(false)
              toast.error(error.response)
              console.log("error login", error)
          }
      }
    }
    return (
        <>
            <main className="main-content login-sec">
                <div className="admin">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xxl-4 col-xl-4 col-md-6 col-sm-8">
                                <div className="edit-profile">
                                    <div className="edit-profile__logos">
                                        <img className="dark" src={ekologo} alt="logodark" />
                                        <img className="light" src={ekologo} alt="logolight" />
                                    </div>
                                    <div className="card border-0">
                                        <div className="card-header">
                                            <div className="edit-profile__title">
                                                <h6>Sign In Sweetz n'snackz</h6>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                          <form onSubmit={handleSubmit}>
                                                <div className="edit-profile__body">
                                                    <div className="form-group">
                                                        <label htmlFor="username">
                                                           Email Address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="username"
                                                            name='email'
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            placeholder="enter your email address"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password-field">password</label>
                                                        <div className="position-relative">
                                                            <input
                                                                id="password-field"
                                                                type={showPassword ? 'text' : "password"}
                                                                className="form-control"
                                                                name="password"
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                placeholder="enter your password"
                                                            />
                                                            <div onClick={() => setShowPassword(!showPassword)} className={`text-lighten fs-15 field-icon toggle-password2 ${showPassword ? 'uil uil-eye' : 'uil uil-eye-slash'}`}></div>
                                                        </div>
                                                    </div>
                                                    <div className="admin-condition justify-content-end">
                                                        <Link to="/forgot-password">forget password?</Link>
                                                    </div>
                                                    <div className="admin__button-group button-group d-flex pt-1 justify-content-md-start justify-content-center mt-4">
                                                        <button type='submit' disabled={loading} className="btn btn-primary btn-default w-100 signIn-createBtn ">
                                                            {loading ? (
                                                                <span className="spinner-border spinner-border-sm"></span>
                                                            ) : (
                                                                "sign in"
                                                            )}
                                                           
                                                        </button>
                                                    </div>
                                                </div>
                                          </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>

    )
}

export default Login