import React from 'react'
const KeywordSearch = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 mb-30">
                        <div className="card mt-30">
                            <div className="card-body">
                                <div className="userDatatable adv-table-table global-shadow border-light-0 w-100 adv-table">
                                    <div className="table-responsive ">
                                        <div className="adv-table-table__header">
                                            <h5>Keyword Search Analyticst</h5>
                                        </div>
                                        <div
                                            id="filter-form-container"
                                            className="footable-filtering-external footable-filtering-right"
                                        >
                                            <form className="form-inline">
                                                <div className="form-group dm-select d-flex align-items-center w-100 justify-content-between adv-table-searchs__status my-xl-25 my-15 mb-0 me-sm-30 me-0">
                                                    <label className="d-flex align-items-center mb-sm-0 mb-2">
                                                        Trending Keywords
                                                    </label>
                                                    <select className="form-control ms-sm-10 ms-0">
                                                        <option>Today</option>
                                                        <option>This week</option>
                                                        <option>Last Week</option>
                                                        <option>All Time</option>
                                                        <option>This Month</option>
                                                        <option>Last Month</option>
                                                        <option>This Year</option>
                                                        <option>Last Year</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='text-center mt-4'>No data Available</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 mb-30">
                        <div className="card mt-30">
                            <div className="card-body">
                                <div className="userDatatable adv-table-table global-shadow border-light-0 w-100 adv-table">
                                    <div className="table-responsive ">
                                        <div className="adv-table-table__header">
                                            <h5>Category Wise Search Volume</h5>
                                        </div>
                                        <div
                                            id="filter-form-container"
                                            className="footable-filtering-external footable-filtering-right"
                                        >
                                            <form className="form-inline">
                                                <div className="form-group dm-select d-flex align-items-center w-100 justify-content-between adv-table-searchs__status my-xl-25 my-15 mb-0 me-sm-30 me-0">
                                                    <label className="d-flex align-items-center mb-sm-0 mb-2">
                                                        Category Wise Search Volume
                                                    </label>
                                                    <select className="form-control ms-sm-10 ms-0">
                                                        <option>Today</option>
                                                        <option>This week</option>
                                                        <option>Last Week</option>
                                                        <option>All Time</option>
                                                        <option>This Month</option>
                                                        <option>Last Month</option>
                                                        <option>This Year</option>
                                                        <option>Last Year</option>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='text-center mt-4'><strong>0</strong> <br />Total Search Volume </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 mb-30">
                        <div className="card mt-30">
                            <div className="card-body">
                                <div className="userDatatable adv-table-table global-shadow border-light-0 w-100 adv-table">
                                    <div className="table-responsive">
                                        <div
                                            id="filter-form-container"
                                            className="footable-filtering-external footable-filtering-right"
                                        >
                                            <form className="form-inline d-flex justify-content-between align-items-center">
                                                <div className="form-group footable-filtering-search me-3">
                                                    <label className="sr-only">Search</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="adv-table-table__button">
                                                    <div className="dropdown">
                                                        <a
                                                            className="btn btn-primary dropdown-toggle dm-select"
                                                            href="#"
                                                            role="button"
                                                            id="dropdownMenuLink"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            Export
                                                        </a>
                                                        <div
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuLink"
                                                        >
                                                            <a className="dropdown-item" href="#">
                                                                copy
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                csv
                                                            </a>
                                                            <a className="dropdown-item" href="#">
                                                                print
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <table
                                            className="table mb-0 table-borderless adv-table footable footable-1 footable-filtering footable-filtering-right footable-paging footable-paging-right breakpoint-lg"
                                            data-sorting="true"
                                            data-filter-container="#filter-form-container"
                                            data-paging-current={1}
                                            data-paging-position="right"
                                            data-paging-size={10}
                                            style={{}}
                                        >
                                            <thead>
                                                <tr className="userDatatable-header footable-header">
                                                    <th
                                                        className="footable-sortable footable-first-visible"

                                                    >
                                                        <span className="userDatatable-title">id</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"

                                                    >
                                                        <span className="userDatatable-title">Keyword</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"

                                                    >
                                                        <span className="userDatatable-title">Search Volume</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"

                                                    >
                                                        <span className="userDatatable-title">Related Categories</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        data-type="html"
                                                        data-name="position"
                                                        className="footable-sortable"

                                                    >
                                                        <span className="userDatatable-title">Related Products</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable footable-last-visible"

                                                    >
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"

                                                    >
                                                        <div className="userDatatable-content">01</div>
                                                    </td>
                                                    <td >
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>fish </h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="userDatatable-content">
                                                            1
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="userDatatable-content">
                                                            1
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                1
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"

                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr className="footable-paging">
                                                    <td colSpan={8}>
                                                        <div className="footable-pagination-wrapper">
                                                            <ul className="pagination">
                                                                <li
                                                                    className="footable-page-nav disabled"
                                                                    data-page="first"
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        «
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    className="footable-page-nav disabled"
                                                                    data-page="prev"
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        ‹
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    className="footable-page visible active"
                                                                    data-page={1}
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        1
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page visible" data-page={2}>
                                                                    <a className="footable-page-link" href="#">
                                                                        2
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page visible" data-page={3}>
                                                                    <a className="footable-page-link" href="#">
                                                                        3
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page-nav" data-page="next">
                                                                    <a className="footable-page-link" href="#">
                                                                        ›
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page-nav" data-page="last">
                                                                    <a className="footable-page-link" href="#">
                                                                        »
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                            <div className="divider" />
                                                            <span className="label label-default">1 of 3</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeywordSearch