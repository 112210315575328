import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';
import axios from 'axios';
const AddCms = () => {
    const [title, setTitle] = useState('');
    const [description, setDiscription] = useState('');
    const modules = {
        toolbar: {
            container: [
                [{ header: [2, 3, 4, false] }],
                ["bold", "italic", "underline", "blockquote"],
                [{ color: [] }],
                [{ background: [] }],
                [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                ],
                ["link", "image"],
                ["clean"],
            ],
            // handlers: {
            //     image: imageHandler,
            // },
        },
        clipboard: {
            matchVisual: true,
        },
    }
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title || !description) {
            toast.error('Title and discription are required');
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/cms/add`, {
                title,
                description,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.data.status) {
                toast.success(response.data.message);
                navigate('/cmslist')
            }
        } catch (err) {
            // Handle error (show error message)
            console.error('Error adding CMS:', err);
            toast.error('Failed to save CMS');
        }
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    add Cms
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                cms Configuration
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Title</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='title'
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            placeholder="enter title"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 col-md-4 mb-3' style={{ height: "200px" }}>
                                                    <ReactQuill style={{height:"160px"}} theme="snow" modules={modules} value={description}
                                                        onChange={setDiscription} />
                                                </div>
                                                <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save cms
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCms;
