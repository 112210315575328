import React, { useEffect, useState } from 'react'
import Header from './Helper/Header'
import Siderbar from './Helper/Siderbar'
import { Outlet, useNavigate } from 'react-router-dom'
import Footer from './Helper/Footer'
import baseUrl from './Helper/BaseUrl'
import { toast } from 'react-toastify'
import axios from 'axios'

const Layout = () => {
    const [userPermissions, setUserPermissions] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const toggleSidebar = () => {
        setIsVisible(!isVisible);
    };
    const navigate = useNavigate();
    useEffect(() => {
        const fetchPermissions = async () => {
            const token = localStorage.getItem("token");

            if (!token) {
                navigate("/login");
                return;
            }

            try {
                const response = await axios.get(`${baseUrl}/admin-role-permissions`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                if (response.data.status) {
                    setUserPermissions(response.data.data.permissions);
                    // console.log('Permissions loaded:', response.data.data.permissions);
                }
            } catch (error) {
                console.error('Permission fetch error:', error);
                toast.error('Failed to load permissions');
                if (error.response?.status === 401) {
                    navigate("/login");
                }
            }
        };

        fetchPermissions();
    }, [navigate]);
    return (
        <>
            <div className='layout-light side-menu'>
                <Header handleToggle={toggleSidebar} />
                <main className="main-content">
                    <Siderbar isVisible={isVisible} userPermissions={userPermissions} />
                    <div className={`contents ${isVisible ? 'expanded' : ''}`}>
                        <Outlet context={{ userPermissions }} />
                    </div>
                    <Footer isVisible={isVisible} />
                </main>
                <div className={`overlay-dark-sidebar ${isVisible ? 'show' : ''}`} />
                <div className="customizer-overlay" />
            </div>
        </>
    )
}

export default Layout