import React from 'react'

const OrderReport = () => {
    return (
        <>
            {/* <div className="contents"> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 mb-30">
                        <div className="card mt-30">
                            <div className="card-body">
                                <div className="userDatatable adv-table-table global-shadow border-light-0 w-100 adv-table">
                                    <div className="table-responsive">
                                        <div className="adv-table-table__header">
                                            <h4>Order Report</h4>
                                            <div className="adv-table-table__button">
                                                <div className="dropdown">
                                                    <a
                                                        className="btn btn-primary dropdown-toggle dm-select"
                                                        href="#"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        Export
                                                    </a>
                                                    <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="dropdownMenuLink"
                                                    >
                                                        <a className="dropdown-item" href="#">
                                                            copy
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            csv
                                                        </a>
                                                        <a className="dropdown-item" href="#">
                                                            print
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* filter */}
                                        <div
                                            id="filter-form-container"
                                            className="footable-filtering-external footable-filtering-right"
                                        >
                                            <form className="form-inline">
                                                <div className="form-group dm-select d-flex align-items-center adv-table-searchs__status my-xl-25 my-15 mb-0 me-sm-30 me-0">
                                                    <label className="d-flex align-items-center mb-sm-0 mb-2">
                                                        Payment Status
                                                    </label>
                                                    <select className="form-control ms-sm-10 ms-0">
                                                        <option>All</option>
                                                        <option>Active</option>
                                                        <option>deactivate</option>
                                                        <option>Blocked</option>
                                                    </select>
                                                </div>
                                                <div className="form-group dm-select d-flex align-items-center adv-table-searchs__position my-xl-25 my-15 me-sm-20 me-0 ">
                                                    <label className="d-flex align-items-center mb-sm-0 mb-2">
                                                        Delivery Status
                                                    </label>
                                                    <select className="form-control ms-sm-10 ms-0">
                                                        <option>All</option>
                                                        <option>Web Developer</option>
                                                        <option>Senior Manager</option>
                                                        <option>UX/UI Desogner</option>
                                                        <option>Content Writer</option>
                                                        <option>Graphic Designer</option>
                                                        <option>Marketer</option>
                                                        <option>Project Manager</option>
                                                        <option>UI Designer</option>
                                                        <option>Business Development</option>
                                                    </select>
                                                </div>
                                                <div className="form-group footable-filtering-search">
                                                    <label className="sr-only">Search</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search"
                                                        />
                                                        {/* <div className="input-group-btn">
                                                            <button type="button" className="btn btn-primary">
                                                                <span className="fooicon fooicon-search" />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default dropdown-toggle"
                                                            >
                                                                <span className="caret" />
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title">
                                                                                id
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title">
                                                                                user
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title">
                                                                                emaill
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title">
                                                                                company
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title">
                                                                                position
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title">
                                                                                join date
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title">
                                                                                status
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="checkbox">
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                defaultChecked="checked"
                                                                            />
                                                                            <span className="userDatatable-title float-end">
                                                                                action
                                                                            </span>
                                                                        </label>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <table
                                            className="table mb-0 table-borderless adv-table footable footable-1 footable-filtering footable-filtering-right footable-paging footable-paging-right breakpoint-lg"
                                            data-sorting="true"
                                            data-filter-container="#filter-form-container"
                                            data-paging-current={1}
                                            data-paging-position="right"
                                            data-paging-size={10}
                                            style={{}}
                                        >
                                            <thead>
                                                <tr className="userDatatable-header footable-header">
                                                    <th
                                                        className="footable-sortable footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">id</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">PlacedOn</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">Item</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">Payment Status</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        data-type="html"
                                                        data-name="position"
                                                        className="footable-sortable"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title">Delivery Status</span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                    <th
                                                        className="footable-sortable footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                        <span className="fooicon fooicon-sort" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">01</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Kellie Marquot </h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            john-keller@gmail.com
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            January 20, 2020
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">02</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Robert Clinton</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">Japan</div>
                                                    </td>
                                                  
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            January 25, 2020
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-warning  color-warning rounded-pill userDatatable-content-status active">
                                                                deactivate
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">03</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Chris Joe</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">South Korea</div>
                                                    </td>
                                                   
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            June 20, 2020
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-danger  color-danger rounded-pill userDatatable-content-status active">
                                                                blocked
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">04</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Jack Kalis</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            South Africa
                                                        </div>
                                                    </td>
                                                   
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            July 30, 2020
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">05</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Black Smith</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            United Kingdom
                                                        </div>
                                                    </td>
                                                
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            August 20, 2020
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">06</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Aftab Ahmed</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">Bangladesh</div>
                                                    </td>
                                                   
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            January 15, 2021
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">07</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Daniel White</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">Australia</div>
                                                    </td>
                                                   
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            January 20, 2021
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">08</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Chris john</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">Japan</div>
                                                    </td>
                                                   
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            February 20, 2021
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">09</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>David Manal</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">Russia</div>
                                                    </td>
                                                  
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            March 20, 2021
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className="footable-first-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <div className="userDatatable-content">10</div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="d-flex">
                                                            <div className="userDatatable-inline-title">
                                                                <a href="#" className="text-dark fw-500">
                                                                    <h6>Kapil Deb</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">India</div>
                                                    </td>
                                                  
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content">
                                                            March 31, 2021
                                                        </div>
                                                    </td>
                                                    <td style={{ display: "table-cell" }}>
                                                        <div className="userDatatable-content d-inline-block">
                                                            <span className="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">
                                                                active
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="footable-last-visible"
                                                        style={{ display: "table-cell" }}
                                                    >
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr className="footable-paging">
                                                    <td colSpan={8}>
                                                        <div className="footable-pagination-wrapper">
                                                            <ul className="pagination">
                                                                <li
                                                                    className="footable-page-nav disabled"
                                                                    data-page="first"
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        «
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    className="footable-page-nav disabled"
                                                                    data-page="prev"
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        ‹
                                                                    </a>
                                                                </li>
                                                                <li
                                                                    className="footable-page visible active"
                                                                    data-page={1}
                                                                >
                                                                    <a className="footable-page-link" href="#">
                                                                        1
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page visible" data-page={2}>
                                                                    <a className="footable-page-link" href="#">
                                                                        2
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page visible" data-page={3}>
                                                                    <a className="footable-page-link" href="#">
                                                                        3
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page-nav" data-page="next">
                                                                    <a className="footable-page-link" href="#">
                                                                        ›
                                                                    </a>
                                                                </li>
                                                                <li className="footable-page-nav" data-page="last">
                                                                    <a className="footable-page-link" href="#">
                                                                        »
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                            <div className="divider" />
                                                            <span className="label label-default">1 of 3</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default OrderReport