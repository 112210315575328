import React from 'react'

const Queries = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">table</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="#">
                                                <i className="uil uil-estate" />
                                                Home
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            table
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header color-dark fw-500">Basic Usage</div>
                            <div className="card-body p-0">
                                <div className="table4 p-25 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th>
                                                        <span className="userDatatable-title">Name</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Email</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Phone</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">Issue</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="userDatatable-content">Mike</div>
                                                    </td>
                                                    <td>
                                                        <div className="userDatatable-content">32@gmail.com</div>
                                                    </td>
                                                    <td>
                                                        <div className="userDatatable-content">
                                                            1012121212
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="userDatatable-content">
                                                            adasd
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                            <li>
                                                                <a href="#" className="view">
                                                                    <i className="uil uil-eye" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="edit">
                                                                    <i className="uil uil-edit" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="remove">
                                                                    <i className="uil uil-trash-alt" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-end pt-30">
                                        <nav className="dm-page ">
                                            <ul className="dm-pagination d-flex">
                                                <li className="dm-pagination__item">
                                                    <a
                                                        href="#"
                                                        className="dm-pagination__link pagination-control"
                                                    >
                                                        <span className="la la-angle-left" />
                                                    </a>
                                                    <a href="#" className="dm-pagination__link">
                                                        <span className="page-number">1</span>
                                                    </a>
                                                    <a href="#" className="dm-pagination__link active">
                                                        <span className="page-number">2</span>
                                                    </a>
                                                    <a href="#" className="dm-pagination__link">
                                                        <span className="page-number">3</span>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="dm-pagination__link pagination-control"
                                                    >
                                                        <span className="page-number">...</span>
                                                    </a>
                                                    <a href="#" className="dm-pagination__link">
                                                        <span className="page-number">12</span>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="dm-pagination__link pagination-control"
                                                    >
                                                        <span className="la la-angle-right" />
                                                    </a>
                                                    <a href="#" className="dm-pagination__option"></a>
                                                </li>
                                                <li className="dm-pagination__item">
                                                    <div className="paging-option">
                                                        <select name="page-number" className="page-selection">
                                                            <option value={20}>20/page</option>
                                                            <option value={40}>40/page</option>
                                                            <option value={60}>60/page</option>
                                                        </select>
                                                    </div>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Queries