import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const CmsList = () => {
    const [cmsList, setCmsList] = useState([]);
    const [loading, setloading] = useState(false)

    useEffect(() => {
        fetchCms();
    }, [])

    // // fetch sub category
    const fetchCms = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/cms/list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("cms", res.data.data)
            setCmsList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch cms error", error)
        }
    }

    // // delete api
    // const handleBannerDelete = async (bannerId) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: 'You won\'t be able to revert this!',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, cancel!',
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             try {
    //                 const res = await axios.delete(`${baseUrl}/hero-slider-delete/${bannerId}`, {
    //                     headers: {
    //                         Authorization: `Bearer ${localStorage.getItem("token")}`
    //                     }
    //                 })
    //                 if (res.data.status) {
    //                     toast.success(res.data.message)
    //                     setBannerList(prevBanner => prevBanner.filter(banner => banner._id !== bannerId));
    //                 } else {
    //                     toast.error(res.data.message)
    //                 }

    //             } catch (error) {
    //                 console.log('error delete subcategory', error)
    //             }
    //         }
    //     })

    // }
    // // // handle sub cat status
    // const handleToggleStatus = async (bannerId, currentStatus) => {
    //     try {
    //         setloading(true)
    //         const newStatus = !currentStatus;
    //         const res = await axios.get(`${baseUrl}/hero-slider-status-change/${bannerId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("token")}`
    //                 }
    //             })
    //         console.log(res);
    //         if (res.data.status) {
    //             toast.success(res.data.message)
    //             setloading(false)
    //             setBannerList(prevBanner =>
    //                 prevBanner.map(banner =>
    //                     banner._id === bannerId ? { ...banner, status: newStatus } : banner
    //                 )
    //             )
    //         }
    //     } catch (error) {
    //         setloading(false)
    //         console.log("toggle sub category status error", error)
    //     }
    // }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">cms list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">cms list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                        // onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                                <div>
                                    <Link className="btn btn-primary" to="/addcms"><span className="uil uil-plus"></span>Add cms list</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Title
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cmsList.map((banner, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{banner.title}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/cmsview/${banner._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/editcms/${banner._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                        {/* <li>
                                                                            <a type='button' className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li> */}
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CmsList
