import React, { useEffect, useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
const ChangePassword = () => {
    const [values, setValues] = useState({
        old_passowrd:"",
        password:"",
        confirmPassword:"",
    });
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
    };
    const validateForm = () => {
        if (!values.old_passowrd) {
            toast.error('Please enter old password');
            return false;
        }
        // if (formData.otp.length !== 6) {
        //     toast.error('OTP must be 6 digits');
        //     return false;
        // }
        if (!values.password) {
            toast.error('Please enter new password');
            return false;
        }
        if (!values.confirmPassword) {
            toast.error('Please enter  confirm Password');
            return false;
        }
        if (values.password.length < 6) {
            toast.error('Password must be at least 6 characters');
            return false;
        }
        if (values.password !== values.confirmPassword) {
            toast.error('Passwords do not match');
            return false;
        }
        return true;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                const response = await axios.post(`${baseUrl}/reset-password`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                // console.log('update', response)
                if (response.data.status) {
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message)
                }
            } catch (error) {
                console.log("profile update error ", error)
            }
        }
       
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Change Password
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Change Password
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="card-header">
                                    <h6 className="fw-500"> Change Password</h6>
                                </div>
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">old passowrd</label>
                                                        <input
                                                            type="password"
                                                            name='old_passowrd'
                                                            value={values.old_passowrd}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="name1"
                                                            placeholder="old password"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="name2"
                                                            name='password'
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            placeholder="password"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">confirm Password</label>
                                                        <input
                                                            type="password"
                                                            name='confirmPassword'
                                                            value={values.confirmPassword}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            id="name2"
                                                            placeholder="confirm password"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                       Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword
