import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Siderbar = ({ isVisible, userPermissions }) => {
    // Separate state for each menu section
    const [openMenus, setOpenMenus] = useState({
        products: false,
        users: false,
        stock: false,
        reports: false,
        newsletter: false,
        refunds: false,
        deliverymens: false,
        rewards: false,
        hero: false,
        shipping: false,
        analytics: false,
        cms:false,
    });

    // Toggle specific menu section
    const toggleMenu = (menuName) => {
        setOpenMenus(prev => ({
            ...prev,
            [menuName]: !prev[menuName]
        }));
    };

    // console.log("===", userPermissions)
    const hasPermission = (permission) => {
        // console.log("12121",permission)
        return userPermissions.includes(permission);
    };
    const location = useLocation();

    return (
        <>
            <div className="sidebar-wrapper">
                <div className={`sidebar sidebar-collapse ${isVisible ? 'collapsed' : ''}`} id="sidebar">
                    <div className="sidebar__menu-group">
                        <ul className="sidebar_nav">
                            <li className='has-child open'>
                                <Link to="/" className="active text-decoration-none">
                                    <span className="nav-icon uil uil-create-dashboard" />
                                    <span className="menu-text">Dashboard</span>
                                </Link>
                            </li>

                            {/* Products Menu */}
                            <li className="menu-title mt-30">
                                <span>Product Management</span>
                            </li>
                            <li className={`has-child ${openMenus.products ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('products')}>
                                    <span className="nav-icon uil uil-bag" />
                                    <span className="menu-text">Products</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.products ? 'd-block' : 'd-none'}>
                                    {hasPermission("Manage Products") && (
                                        <li className={location.pathname === '/productlist' ? 'active' : ''}>
                                            <Link to="/productlist" className='text-decoration-none'>Products List</Link>
                                        </li>)}
                                    {hasPermission("Manage Product Reviews") && (
                                        <li className={location.pathname === '/productreview' ? 'active' : ''}>
                                            <Link to="/productreview" className='text-decoration-none'>Products Reviews</Link>
                                        </li>)}
                                    {hasPermission("Manage Categories") && (
                                        <li className={location.pathname === '/categorylist' ? 'active' : ''}>
                                            <Link to="/categorylist" className='text-decoration-none'>Categories</Link>
                                        </li>)}
                                    {hasPermission("Manage Sub Category") && (
                                        <li className={location.pathname === '/subcategorylist' ? 'active' : ''}>
                                            <Link to="/subcategorylist" className='text-decoration-none'>Sub Categories</Link>
                                        </li>)}
                                    {hasPermission("Manage Orders") && (
                                        <li className={location.pathname === '/order' ? 'active' : ''}>
                                            <Link to="/order" className='text-decoration-none'>Orders</Link>
                                        </li>)}
                                    {/* <li className={location.pathname === '/brands' ? 'active' : ''}>
                                        <Link to="/brands" className='text-decoration-none'>Brands</Link>
                                    </li>
                                    <li className={location.pathname === '/units' ? 'active' : ''}>
                                        <Link to="/units" className='text-decoration-none'>All Units</Link>
                                    </li>
                                    <li className={location.pathname === '/variants' ? 'active' : ''}>
                                        <Link to="/variants" className='text-decoration-none'>All variants</Link>
                                    </li> */}
                                </ul>
                            </li>
                            {/* Stock Menu */}
                            {/* <li className={`has-child ${openMenus.stock ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('stock')}>
                                    <span className="nav-icon uil uil-location-point" />
                                    <span className="menu-text">Stock</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.stock ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/addstock' ? 'active' : ''}>
                                        <Link to="/addstock" className='text-decoration-none'>Add Stock</Link>
                                    </li>
                                    <li className={location.pathname === '/locations' ? 'active' : ''}>
                                        <Link to="/locations" className='text-decoration-none'>All Locations</Link>
                                    </li>

                                </ul>
                            </li> */}

                            {/* Users Menu */}
                            <li className="menu-title mt-30">
                                <span>Users</span>
                            </li>
                            <li className={`has-child ${openMenus.users ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('users')}>
                                    <span className="nav-icon uil uil-users-alt"></span>
                                    <span className="menu-text">Users</span>
                                    <span className="toggle-icon"></span>
                                </a>

                                <ul className={openMenus.users ? 'd-block' : 'd-none'}>
                                    {hasPermission("Manage staff") && (
                                        <li className={location.pathname === '/staff' ? 'active' : ''}>
                                            <Link to="/staff" className='text-decoration-none'>Team/Staff</Link>
                                        </li>)}
                                    {hasPermission("Manage users") && (
                                        <li className={location.pathname === '/userlist' ? 'active' : ''}>
                                            <Link to="/userlist" className='text-decoration-none'>Users</Link>
                                        </li>)}
                                </ul>
                            </li>
                            {/* deliverymens Menu */}
                            {/* <li className={`has-child ${openMenus.deliverymens ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('deliverymens')}>
                                    <span className="nav-icon uil uil-users-alt"></span>
                                    <span className="menu-text">Delivery Mens</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.deliverymens ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/deliverymens' ? 'active' : ''}>
                                        <Link to="/deliverymens" className='text-decoration-none'>Delivery Mens</Link>
                                    </li>
                                    <li className={location.pathname === '/cancelrequest' ? 'active' : ''}>
                                        <Link to="/cancelrequest" className='text-decoration-none'>Cancel Request</Link>
                                    </li>
                                    <li className={location.pathname === '/configurations' ? 'active' : ''}>
                                        <Link to="/configurations" className='text-decoration-none'>Configurations</Link>
                                    </li>
                                    <li className={location.pathname === '/deliverymenpayroll' ? 'active' : ''}>
                                        <Link to="/deliverymenpayroll" className='text-decoration-none'>Deliverymen Payroll</Link>
                                    </li>
                                    <li className={location.pathname === '/deliverymenpayrolllist' ? 'active' : ''}>
                                        <Link to="/deliverymenpayrolllist" className='text-decoration-none'>Deliverymen Payroll List</Link>
                                    </li>
                                    <li className={location.pathname === '/payouthistory' ? 'active' : ''}>
                                        <Link to="/payouthistory" className='text-decoration-none'>Payout History</Link>
                                    </li>
                                </ul>
                            </li> */}

                            {/* Reports Menu */}
                            {/* <li className="menu-title mt-30">
                                <span>Reports</span>
                            </li>
                            <li className={`has-child ${openMenus.reports ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('reports')}>
                                    <span className="nav-icon uil uil-book-open"></span>
                                    <span className="menu-text">Reports</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.reports ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/orderreport' ? 'active' : ''}>
                                        <Link to="/orderreport" className='text-decoration-none'>Order Report</Link>
                                    </li>
                                    <li className={location.pathname === '/productsalereport' ? 'active' : ''}>
                                        <Link to="/productsalereport" className='text-decoration-none'>Product Sale Report</Link>
                                    </li>
                                    <li className={location.pathname === '/categorywisereport' ? 'active' : ''}>
                                        <Link to="/categorywisereport" className='text-decoration-none'>Category Wise sales</Link>
                                    </li>
                                    <li className={location.pathname === '/salesamountreport' ? 'active' : ''}>
                                        <Link to="/salesamountreport" className='text-decoration-none'>Sales Amount Report</Link>
                                    </li>
                                    <li className={location.pathname === '/deliverystatusreport' ? 'active' : ''}>
                                        <Link to="/deliverystatusreport" className='text-decoration-none'>Delivery Status Report</Link>
                                    </li>
                                </ul>
                            </li> */}
                            {/* analytics menu  */}
                            {/* <li className={`has-child ${openMenus.analytics ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('analytics')}>
                                    <span className="nav-icon uil uil-analytics"></span>
                                    <span className="menu-text">Analytics</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.analytics ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/keywordsearch' ? 'active' : ''}>
                                        <Link to="/keywordsearch" className='text-decoration-none'>Keyword Search</Link>
                                    </li>
                                    <li className={location.pathname === '/customersearch' ? 'active' : ''}>
                                        <Link to="/customersearch" className='text-decoration-none'>Customer Search</Link>
                                    </li>
                                </ul>
                            </li> */}

                            {/* Supports querirs*/}
                            {/* <li className="menu-title mt-30">
                                <span>Supports</span>
                            </li>
                            <li className='has-child open'>
                                <Link to="/queries" className="active text-decoration-none">
                                    <span className="nav-icon uil uil-question-circle" />
                                    <span className="menu-text">Queries</span>
                                </Link>
                            </li> */}
                            {hasPermission("Manage Roles") && (
                                <>
                                    <li className="menu-title mt-30">
                                        <span>Roles</span>
                                    </li>
                                    <li className='has-child open'>
                                        <Link to="/rolelist" className="active text-decoration-none">
                                            <span className="nav-icon uil uil-question-circle" />
                                            <span className="menu-text">Roles</span>
                                        </Link>
                                    </li>
                                </>
                            )}
                            <li className="menu-title mt-30">
                                <span>Roles</span>
                            </li>
                            {/* {hasPermission("Manage Coupons") && ( */}
                            <li className='has-child open'>
                                <Link to="/couponlist" className="active text-decoration-none">
                                    <span className="nav-icon uil uil-gift" />
                                    <span className="menu-text">Coupons</span>
                                </Link>
                            </li>
                            {/* )} */}
                            {/* newsletter Menu */}
                            {/* <li className="menu-title mt-30">
                                <span>Promotions</span>
                            </li>
                            <li className={`has-child ${openMenus.newsletter ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('newsletter')}>
                                    <span className="nav-icon uil uil-chart"></span>
                                    <span className="menu-text">Newsletters</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.newsletter ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/bulkemails' ? 'active' : ''}>
                                        <Link to="/bulkemails" className='text-decoration-none'>Bulk Emails</Link>
                                    </li>
                                    <li className={location.pathname === '/subscribers' ? 'active' : ''}>
                                        <Link to="/subscribers" className='text-decoration-none'>Subscribers</Link>
                                    </li>
                                    <li className={location.pathname === '/coupons' ? 'active' : ''}>
                                        <Link to="/coupons" className='text-decoration-none'>Coupons</Link>
                                    </li>
                                </ul>
                            </li> */}

                            {/* refunds Menu */}
                            {/* <li className="menu-title mt-30">
                                <span>Refunds</span>
                            </li>
                            <li className={`has-child ${openMenus.refunds ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('refunds')}>
                                    <span className="nav-icon uil uil-arrow-circle-left"></span>
                                    <span className="menu-text">refunds</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.refunds ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/refundconfigurations' ? 'active' : ''}>
                                        <Link to="/refundconfigurations" className='text-decoration-none'>Refund Configurations</Link>
                                    </li>
                                    <li className={location.pathname === '/refundrequests' ? 'active' : ''}>
                                        <Link to="/refundrequests" className='text-decoration-none'>Refund Requests</Link>
                                    </li>
                                    <li className={location.pathname === '/approvedrefunds' ? 'active' : ''}>
                                        <Link to="/approvedrefunds" className='text-decoration-none'>Approved Refunds</Link>
                                    </li>
                                    <li className={location.pathname === '/rejectsrefunds' ? 'active' : ''}>
                                        <Link to="/rejectsrefunds" className='text-decoration-none'>Rejected Refunds</Link>
                                    </li>
                                </ul>
                            </li> */}

                            {/* Rewards & Wallet Menu */}
                            {/* <li className="menu-title mt-30">
                                <span>Rewards & Wallet </span>
                            </li>
                            <li className={`has-child ${openMenus.rewards ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('rewards')}>
                                    <span className="nav-icon uil uil-wallet"></span>
                                    <span className="menu-text">Rewards & Wallet</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.rewards ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/rewardsconfigurations' ? 'active' : ''}>
                                        <Link to="/rewardsconfigurations" className='text-decoration-none'>Rewards Configurations</Link>
                                    </li>
                                    <li className={location.pathname === '/setrewardpoints' ? 'active' : ''}>
                                        <Link to="/setrewardpoints" className='text-decoration-none'>Set Reward Points</Link>
                                    </li>
                                    <li className={location.pathname === '/walletconfigurations' ? 'active' : ''}>
                                        <Link to="/walletconfigurations" className='text-decoration-none'>Wallet Configurations</Link>
                                    </li>
                                </ul>
                            </li> */}

                            {/* hero section Menu */}
                            <li className="menu-title mt-30">
                                <span>Appearance </span>
                            </li>
                            <li className={`has-child ${openMenus.hero ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('hero')}>
                                    <span className="nav-icon uil uil-home"></span>
                                    <span className="menu-text">Hero section</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.hero ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/bannerslider' ? 'active' : ''}>
                                        <Link to="/bannerslider" className='text-decoration-none'>Banner Slider section</Link>
                                    </li>
                                    <li className={location.pathname === '/faqlist' ? 'active' : ''}>
                                        <Link to="/faqlist" className='text-decoration-none'>FAQ</Link>
                                    </li>
                                    <li className={location.pathname === '/subscribers' ? 'active' : ''}>
                                        <Link to="/subscribers" className='text-decoration-none'>Subscribers</Link>
                                    </li>
                                    <li className={location.pathname === '/settings' ? 'active' : ''}>
                                        <Link to="/settings" className='text-decoration-none'>Settings</Link>
                                    </li>
                                    <li className={location.pathname === '/contacts' ? 'active' : ''}>
                                        <Link to="/contacts" className='text-decoration-none'>Help</Link>
                                    </li>
                                    {/* <li className={location.pathname === '/sale' ? 'active' : ''}>
                                        <Link to="/sale" className='text-decoration-none'>Seasonal Sale section</Link>
                                    </li>
                                    <li className={location.pathname === '/advertisement' ? 'active' : ''}>
                                        <Link to="/advertisement" className='text-decoration-none'>Advertisement Section</Link>
                                    </li> */}
                                </ul>
                            </li>
                            <li className="menu-title mt-30">
                                <span>cms Management</span>
                            </li>
                            <li className={`has-child ${openMenus.cms ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('cms')}>
                                    <span className="nav-icon uil uil-home"></span>
                                    <span className="menu-text">cms section</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.cms ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/cmslist' ? 'active' : ''}>
                                        <Link to="/cmslist" className='text-decoration-none'>CMS List</Link>
                                    </li>
                                    {/* <li className={location.pathname === '/aboutus' ? 'active' : ''}>
                                        <Link to="/aboutus" className='text-decoration-none'>About us</Link>
                                    </li>
                                    <li className={location.pathname === '/privacypolicy' ? 'active' : ''}>
                                        <Link to="/privacypolicy" className='text-decoration-none'>Privacy Policy</Link>
                                    </li>
                                    <li className={location.pathname === '/termsconditions' ? 'active' : ''}>
                                        <Link to="/termsconditions" className='text-decoration-none'>Terms Conditions</Link>
                                    </li> */}
                                </ul>
                            </li>


                            {/* Shipping Menu */}
                            {/* <li className="menu-title mt-30">
                                <span>Fullfilment</span>
                            </li>
                            <li className={`has-child ${openMenus.shipping ? 'open' : ''}`}>
                                <a className="active text-decoration-none" onClick={() => toggleMenu('shipping')}>
                                    <span className="nav-icon uil uil-truck"></span>
                                    <span className="menu-text">Shipping Zone</span>
                                    <span className="toggle-icon"></span>
                                </a>
                                <ul className={openMenus.shipping ? 'd-block' : 'd-none'}>
                                    <li className={location.pathname === '/shippingzone' ? 'active' : ''}>
                                        <Link to="/shippingzone" className='text-decoration-none'>Shipping Zone</Link>
                                    </li>
                                </ul>
                            </li> */}

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Siderbar;
