import React, { useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import trash from "../../assets/img/svg/trash-2.svg"
import media from "../../assets/img/food-restaurant-media.png"
import axios from 'axios'
import baseUrl from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
const AddBrands = () => {
    const [values, setValues] = useState({
        brand_name: "",
        status: "",
    })
    const [imgFile, setImgFile] = useState(null)
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2VjNmE3MWI5YjNkNWFmYWU1ODRjZSIsIm1vYmlsZU51bWJlciI6ODk2NTk1MTE1MSwiZW1haWwiOiJtYW5pc2hAZ2FtaWwuY29tIiwiaWF0IjoxNzMyNjAxMjA2fQ.b7vfdebycvD7dNCAkTQXHOmHOiFvmXiTKaBZWf2jA1U'
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!values.brand_name || !values.status)  {
            toast.error("All fields are required!");
            return;
        }
        if (!imgFile) {
            toast.error("Please upload an image.");
            return;
        }
        try {
            const formData = new FormData();
            formData.append('brand_name', values.brand_name)
            formData.append('status', values.status)
            if (imgFile) {
                formData.append('brand_image', imgFile)
            }
            const res = await axios.post(`${baseUrl}/brand-store`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log("****",res);
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/brands')
            } else {
                toast.error(res.data.message)
            }

        } catch (error) {
            console.log("category post error", error)
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Add New Brands
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                add Brands
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">Add Brands</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Brand name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name='brand_name'
                                                                value={values.brand_name}
                                                                onChange={handleChange}
                                                                placeholder="enter brand name"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Status</label>
                                                                <select
                                                                    name='status'
                                                                    value={values.status}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">select status</option>
                                                                    <option value="true">Active</option>
                                                                    <option value="false">inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="card add-product p-sm-30 p-20 ">
                                                            <div className="card-body p-0">
                                                                <div className="card-header">
                                                                    <h6 className="fw-500">Brand image</h6>
                                                                </div>
                                                                <div className="add-product__body-img px-sm-40 px-20">
                                                                    <label
                                                                        htmlFor="upload"
                                                                        className="file-upload__label"
                                                                    >
                                                                        <span className="upload-product-img px-10 d-block">
                                                                            <span className="file-upload">
                                                                                <img
                                                                                    className="svg"
                                                                                    src={upload}
                                                                                    alt=""
                                                                                />
                                                                                <input
                                                                                    id="upload"
                                                                                    className="file-upload__input"
                                                                                    type="file"
                                                                                    name="file-upload"
                                                                                    onChange={(e) => setImgFile(e.target.files[0])}
                                                                                />
                                                                            </span>
                                                                           
                                                                        </span>
                                                                    </label>
                                                                    <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                                        <div className="upload-media-area">
                                                                            {
                                                                                imgFile ? (
                                                                                    <img
                                                                                        src={URL.createObjectURL(imgFile)}
                                                                                        alt="img"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={media}
                                                                                        alt="img"
                                                                                    />
                                                                                )
                                                                            }
                                                                          
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save Brand
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBrands