import React, { useEffect, useState } from 'react'
import doller from "../../assets/img/svg/dollar-sign.svg"
import percent from "../../assets/img/svg/percent.svg"
import media from "../../assets/img/food-restaurant-media.png"
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import axios from 'axios'
const OrderView = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        user_id: "",
        date: "",
        total_amount: "",
        status: "",
    })
    const [catList, setCatList] = useState([]);
    const [loading, setloading] = useState(false)

    // product view api
    const fetchSingleProduct = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/order-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })

            // console.log(res)
            setValues(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("single product error", error)
        }
    }

    useEffect(() => {
        fetchSingleProduct();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/order">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / Order Details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Order Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='card'>
                            <div className='card-header'></div>
                            <div className='card-body'>
                                <div className="add-product__body px-sm-40 px-20">
                                    <form >
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">Customers</label>
                                                        <input type="text" className="form-control" id="name1" name='user_id' value={values.user_id} disabled placeholder="customers" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Order Status</label>
                                                        <input type="text" className="form-control" name='status' value={values.status} disabled id="name2" placeholder="status" />
                                                    </div>
                                                </div>

                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Amount</label>
                                                        <input type="text" className="form-control" name='total_amount' value={values.total_amount} disabled id="name2" placeholder="total Amounts"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2">Date</label>
                                                        <input type="text" className="form-control" name='date' value={values.date} disabled id="name2" placeholder="date"
                                                        />
                                                    </div>
                                                </div>
                                             

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default OrderView



