import React from "react";

const Pagination = ({ currentPage, onPageChange, totalItems, itemPerPage }) => {
    // console.log("currentPage", currentPage)//1
    // console.log("onPageChange", onPageChange)
    // console.log("totalItems", totalItems) // 7
    // console.log("itemPerPage", itemPerPage) // 1

    const maxPages = Math.ceil(totalItems / itemPerPage);
    // console.log("maxPAges", maxPages)
    const gotoPrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };
    const gotoNextPage = () => {
        if (currentPage < maxPages) {
            onPageChange(currentPage + 1);
        }
    };
    return (
        <>
            <div className="d-flex justify-content-end pt-30 mt-3">
                <nav aria-label="..." className="dm-page">
                    <ul className="dm-pagination d-flex">
                        {currentPage > 1 && (
                            <li className="dm-pagination__item">
                                <button
                                    className="dm-pagination__link pagination-control"
                                    onClick={gotoPrevPage}
                                    disabled={currentPage === 1}
                                    aria-label="Previous"
                                >
                                    <span className="fa fa-angle-left"></span>
                                </button>
                            </li>
                        )}
                        {currentPage > 1 && (
                            // <li className="page-item">
                            <a
                                className="dm-pagination__link"
                                onClick={() => onPageChange(currentPage - 1)}
                                disabled={currentPage === 1}

                            >

                                <span className="page-number">{currentPage - 1}</span>
                            </a>
                            // </li>
                        )}
                        <a className="dm-pagination__link active">
                            <span className="page-number" >
                                {currentPage}
                            </span>
                        </a>

                        {currentPage < maxPages && (
                            // <li className="page-item">
                            <a
                                className="dm-pagination__link "
                                onClick={() => onPageChange(currentPage + 1)}
                            >
                                {currentPage + 1}
                            </a>
                            // </li>
                        )}
                        {currentPage < maxPages && (
                            <li className="page-item">  
                                <button
                                    className="dm-pagination__link pagination-control"
                                    onClick={gotoNextPage}
                                    disabled={currentPage >= maxPages}
                                    aria-label="Next"
                                >
                                    <span className="fa fa-angle-right" />
                                </button>
                            </li>
                        )}
                    </ul>
                </nav>
            </div >
            {/* <div className="d-flex justify-content-end pt-30">
                                <nav className="dm-page">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div> */}
        </>
    );
};

export default Pagination;
