import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import { Link } from 'react-router-dom'
import baseUrl, { imgUrl } from '../Helper/BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
const Brands = () => {
    const [brandList, setBrandList] = useState([]);
    const [loading, setLoading] = useState(false)
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2VjNmE3MWI5YjNkNWFmYWU1ODRjZSIsIm1vYmlsZU51bWJlciI6ODk2NTk1MTE1MSwiZW1haWwiOiJtYW5pc2hAZ2FtaWwuY29tIiwiaWF0IjoxNzMyNjAxMjA2fQ.b7vfdebycvD7dNCAkTQXHOmHOiFvmXiTKaBZWf2jA1U'
    // brands api
    const fetchBrands = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${baseUrl}/brand-list`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log("brand", res.data.data)
            setBrandList(res.data.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("fetch brand error", error)
        }
    }

    // delete api
    const handleBrandDelete = async (brandId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/brand-delete/${brandId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    console.log(res)
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setBrandList(prevBrand => prevBrand.filter(brand => brand._id !== brandId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete unit', error)
                }
            }})
       
    }
    useEffect(() => {
        fetchBrands();
    }, [])

    //breand status change api
    const handleStatusToggle = async (brandId, currentStatus) => {
        try {
            const newStatus = !currentStatus;
            const res = await axios.post(`${baseUrl}/brand-status-change/${brandId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            console.log(res)
            if (res.data.status) {
                toast.success(res.data.message)
                setBrandList(prevBrands =>
                    prevBrands.map(brand =>
                        brand._id === brandId ? { ...brand, status: newStatus } : brand
                    )

                )
            }
        } catch (error) {
            console.log("toggle brands status error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main user-member justify-content-sm-between ">
                            <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div className="d-flex align-items-center user-member__title justify-content-center me-sm-25">
                                    <h4 className="text-capitalize fw-500 breadcrumb-title">
                                        Brands list{"   "}
                                    </h4>
                                    <span className="sub-title ms-sm-25 ps-sm-25 ms-2">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="uil uil-estate" />
                                                Home
                                            </Link>
                                        </li>
                                    </span>
                                </div>
                                <form
                                    action=""
                                    className="d-flex align-items-center user-member__form my-sm-0 my-2"
                                >
                                    <img
                                        src={searchImg}
                                        alt="search"
                                        className="svg"
                                    />
                                    <input
                                        className="form-control me-sm-2 border-0 box-shadow-none"
                                        type="search"
                                        placeholder="Search by Name"
                                        aria-label="Search"
                                    />
                                </form>
                            </div>
                            <div className="action-btn">
                                <Link to="/addbrands" className="btn px-15 btn-primary">
                                    <i className="fas fa-plus fs-16" />
                                    Add Brands
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                            <div className="table-responsive">
                                <table className="table mb-0 table-borderless">
                                    <thead>
                                        <tr className="userDatatable-header">
                                            <th><span className="userDatatable-title">S.No.</span></th>
                                            <th>
                                                <span className="userDatatable-title">Brand Image</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">
                                                    Brand Name
                                                </span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">status</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title float-end">
                                                    action
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            brandList.map((brand, index) => {
                                                return (
                                                    <tr key={brand?._id}>
                                                        <td>
                                                            <div className="userDatatable-content">{index + 1}</div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">
                                                                <img src={`${imgUrl}/${brand?.brand_image}`} className="profile-image rounded-circle d-block m-0 wh-38" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-inline-title">
                                                                <h6>{brand?.brand_name}</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content d-inline-block">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" checked={brand?.status}
                                                                        onChange={() => handleStatusToggle(brand?._id, brand?.status)}
                                                                        type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                <li>
                                                                    <Link to={`/brandsview/${brand._id}`} className="view">
                                                                        <i className="uil uil-eye" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={`/editbrands/${brand._id}`} className="edit">
                                                                        <i className="uil uil-edit" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <a type="button" onClick={() => handleBrandDelete(brand._id)} className="remove">
                                                                        <i className="uil uil-trash-alt" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="d-flex justify-content-end pt-30">
                                <nav className="dm-page ">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Brands