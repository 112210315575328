import React from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import { Link } from 'react-router-dom'
const Deliverymens = () => {
    return (
        <>
            {/* <div className="contents"> */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">Delivery Mens</h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="#">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                Delivery men
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="userDatatable orderDatatable sellerDatatable global-shadow mb-30 py-30 px-sm-30 px-20 radius-xl w-100">
                            <div className="project-top-wrapper d-flex justify-content-between flex-wrap mb-25 mt-n10">
                                <div className="d-flex align-items-center flex-wrap justify-content-center">
                                    <div className="project-search order-search  global-shadow mt-10">
                                        <form action="/" className="order-search__form">
                                            <img src={searchImg} alt="search" className="svg" />
                                            <input
                                                className="form-control me-sm-2 border-0 box-shadow-none"
                                                type="search"
                                                placeholder="Filter by keyword"
                                                aria-label="Search"
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className="content-center">
                                    <div className="button-group m-0 mt-sm-0 mt-10 order-button-group">
                                        <button
                                            type="button"
                                            className="order-bg-opacity-secondary text-secondary btn radius-md"
                                        >
                                            Export
                                        </button>
                                        <Link
                                            to="/adddeliverymen"
                                            className="btn btn-sm btn-primary me-0 radius-md"
                                        >
                                            <i className="fa fa-plus" /> Add Delivery Men
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table mb-0 table-borderless border-0">
                                    <thead>
                                        <tr className="userDatatable-header">
                                        <th scope="col">
                                                <span className="userDatatable-title">S.No.</span>
                                            </th>
                                            <th scope="col">
                                                <span className="userDatatable-title">Name</span>
                                            </th>
                                            <th scope="col">
                                                <span className="userDatatable-title">Location</span>
                                            </th>
                                            <th scope="col">
                                                <span className="userDatatable-title">Email</span>
                                            </th>
                                            <th scope="col">
                                                <span className="userDatatable-title">Phone</span>
                                            </th>
                                            <th scope="col">
                                                <span className="userDatatable-title">Joinning date</span>
                                            </th>
                                            <th scope="col">
                                                <span className="userDatatable-title float-end">
                                                    Balance
                                                </span>
                                            </th>
                                            <th scope="col" className="text-end">
                                                <span className="userDatatable-title ">Actions</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="orderDatatable-title">1. </div>
                                            </td>
                                            <td>
                                                <div className="orderDatatable-title">Marquot Store</div>
                                            </td>
                                            <td>
                                                <div className="orderDatatable-title">Jaipur</div>
                                            </td>
                                            <td>
                                                <div className="orderDatatable-title">324@gmail.com</div>
                                            </td>
                                            <td>
                                                <div className="orderDatatable-title">458424866</div>
                                            </td>

                                            <td>
                                                <div className="orderDatatable-title float-end">
                                                    January 20, 2020
                                                </div>
                                            </td>
                                            <td>
                                                <div className="orderDatatable-title">458424866</div>
                                            </td>
                                            <td>
                                                <ul className="orderDatatable_actions mb-0 d-flex flex-wrap float-end">
                                                    <li>
                                                        <a href="#" className="edit">
                                                            <i className="uil uil-edit" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="remove">
                                                            <i className="uil uil-trash-alt" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end mt-15 pt-25 border-top">
                                <nav className="dm-page ">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default Deliverymens