import React, { useState } from 'react'
import upload from "../../assets/img/svg/upload.svg"
import doller from "../../assets/img/svg/dollar-sign.svg"
import percent from "../../assets/img/svg/percent.svg"
import media from "../../assets/img/food-restaurant-media.png"
import trash from "../../assets/img/svg/trash-2.svg"
// import { DateRangePicker } from 'react-date-range';
// import { addDays } from 'date-fns';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
const AddCoupons = () => {
    // const [state, setState] = useState([
    //     {
    //         startDate: new Date(),
    //         endDate: addDays(new Date(), 7),
    //         key: 'selection'
    //     }
    // ]);
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    Add Coupons
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="#">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Add Coupons
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        {/* Start: product page */}
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        {/* Start: card */}
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500"> Add Coupons</h6>
                                                </div>
                                                {/* Start: card body */}
                                                <div className="add-product__body px-sm-40 px-20">
                                                    {/* Start: form */}
                                                    <form>
                                                        {/* form group */}
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Themes/Item</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                placeholder="red chair"
                                                            />
                                                        </div>
                                                        {/* form group 1 */}
                                                        <div className="form-group">
                                                            <label htmlFor="name2">Coupon Code</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name2"
                                                                placeholder="description"
                                                            />
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group quantity-appearance col-md-6">
                                                                <label>Discount Amount</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            className="input-group-text"
                                                                            id="basic-addon1"
                                                                        >
                                                                            <img
                                                                                className="svg"
                                                                                src={doller}
                                                                                alt=""
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <div className="pt_Quantity">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            min={0}
                                                                            max={100}
                                                                            step={1}
                                                                            defaultValue={0}
                                                                            data-inc={1}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* form group 4 */}
                                                            <div className="form-group quantity-appearance col-md-6">
                                                                <label>Percent or Fixed</label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            className="input-group-text"
                                                                            id="basic-addon2"
                                                                        >
                                                                            <img
                                                                                className="svg"
                                                                                src={percent}
                                                                                alt=""
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                    <div className="pt_Quantity">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            min={0}
                                                                            max={100}
                                                                            step={1}
                                                                            defaultValue={0}
                                                                            data-inc={1}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group quantity-appearance col-md-6">
                                                                <label>Date Range</label>

                                                                {/* <DateRangePicker
                                                                    onChange={item => setState([item.selection])}
                                                                    showSelectionPreview={true}
                                                                    moveRangeOnFirstSelection={false}
                                                                    months={2}
                                                                    ranges={state}
                                                                    direction="horizontal"
                                                                /> */}
                                                            </div>
                                                            <div className="form-group quantity-appearance col-md-6">
                                                                <label>Free Shpping?</label>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                    <label className="form-check-label" for="flexSwitchCheckDefault">Allow Free Shipping?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card add-product p-sm-30 p-20 ">
                                                            <div className="card-body p-0">
                                                                <div className="card-header">
                                                                    <h6 className="fw-500">Banner image</h6>
                                                                </div>
                                                                {/* Start: product body */}
                                                                <div className="add-product__body-img px-sm-40 px-20">
                                                                    <label
                                                                        htmlFor="upload"
                                                                        className="file-upload__label"
                                                                    >
                                                                        <span className="upload-product-img px-10 d-block">
                                                                            <span className="file-upload">
                                                                                <img
                                                                                    className="svg"
                                                                                    src={upload}
                                                                                    alt=""
                                                                                />
                                                                                <input
                                                                                    id="upload"
                                                                                    className="file-upload__input"
                                                                                    type="file"
                                                                                    name="file-upload"
                                                                                />
                                                                            </span>
                                                                            <span className="pera">
                                                                                Drag and drop an image
                                                                            </span>
                                                                            <span>
                                                                                or{" "}
                                                                                <a href="#" className="color-secondary">
                                                                                    Browse
                                                                                </a>{" "}
                                                                                to choose a banner file
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                    <div className="upload-product-media d-flex justify-content-between align-items-center mt-25">
                                                                        <div className="upload-media-area d-flex">
                                                                            <img
                                                                                src={media}
                                                                                alt="img"
                                                                            />
                                                                            <div className=" upload-media-area__title  d-flex flex-wrap align-items-center ms-10">
                                                                                <div>
                                                                                    <p>Banner_image.jpg</p>
                                                                                    <span>68.8 KB</span>
                                                                                </div>
                                                                                <div className="upload-media-area__btn">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="transparent rounded-circle wh-30 border-0 color-danger"
                                                                                    >
                                                                                        <img
                                                                                            className="svg"
                                                                                            src={trash}
                                                                                            alt=""
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* End: product body */}
                                                            </div>
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button className="btn btn-light btn-default btn-squared fw-400 text-capitalize">
                                                                cancel
                                                            </button>
                                                            <button className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save coupons
                                                            </button>
                                                        </div>
                                                    </form>
                                                    {/* End: form */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End: button group */}
                                    </div>
                                </div>
                                {/* ends: col-lg-8 */}
                            </div>
                        </div>
                        {/* End: Product page */}
                    </div>
                    {/* ends: col-lg-12 */}
                </div>
            </div>
        </>
    )
}

export default AddCoupons