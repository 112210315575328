import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
const ResetPassword = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        password: "",
        confirmPassword: "",
    })
    const [loading, setloading] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: value
        }))
    }
    const navigate = useNavigate()
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2VjNmE3MWI5YjNkNWFmYWU1ODRjZSIsIm1vYmlsZU51bWJlciI6ODk2NTk1MTE1MSwiZW1haWwiOiJtYW5pc2hAZ2FtaWwuY29tIiwiaWF0IjoxNzMyNjAxMjA2fQ.b7vfdebycvD7dNCAkTQXHOmHOiFvmXiTKaBZWf2jA1U'
    useEffect(() => {
        const fetchLink = async () => {
            setloading(true)
            try {
                const res = await axios.get(`${baseUrl}/check-link/${id}`, {
                })
                console.log(res.data.status)
                if (!(res.data.status)) {
                    navigate('/expirelink')
                    setloading(false)
                } else {
                    setloading(false)
                }
            } catch (error) {
                setloading(false)
                console.log("fetch link error", error)
                navigate('/expirelink')
            }
        }
        fetchLink();
    }, [])

    console.log(values)
    const validateSetPassword = () => {
        const { password, confirmPassword, } = values;
        if (!password) {
            toast.error('Password Field Required');
            return false;
        }
        // if (password.length < 8) {
        //     toast.error('Password must be at least 8 characters long');
        //     return false;
        // }
        if (!confirmPassword) {
            toast.error('Confirm Password Field Required');
            return false;
        }
        if (password !== confirmPassword) {
            toast.error('Confirm Password Not Matched');
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateSetPassword()) {
            setloading(true)
            try {
                const res = await axios.post(`${baseUrl}/set-password/${id}`, values, {

                })
                // console.log(res)
                if (res.data.status) {
                    toast.success(res.data.message);
                    setloading(false)
                    navigate('/login')
                } else {
                    setloading(false)
                    toast.error(res.data.message)
                    navigate('/expirelink')
                }
            } catch (error) {
                setloading(false)
                console.log("error in reset password", error)
            }
        }

    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>)}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 ">
                        <div className="p-5">
                            <h4 className="text-capitalize breadcrumb-title text-center">
                                Password
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-8">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500">set Password</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Password</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="name1"
                                                                name='password'
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                placeholder="type password"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Confirm Password</label>
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                id="name1"
                                                                name='confirmPassword'
                                                                value={values.confirmPassword}
                                                                onChange={handleChange}
                                                                placeholder="type confirm password"
                                                            />
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save Password
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword