import React from 'react'

const ApprovedRefunds = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main user-member justify-content-sm-between ">
                            <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div className="d-flex align-items-center user-member__title justify-content-center me-sm-25">
                                    <h4 className="text-capitalize fw-500 breadcrumb-title">
                                        Approved Refunds{"   "}
                                    </h4>
                                    <span className="sub-title ms-sm-25 ps-sm-25 ms-2"> Home</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                            <div className="table-responsive">
                                <table className="table mb-0 table-borderless">
                                    <thead>
                                        <tr className="userDatatable-header">
                                            <th><span className="userDatatable-title">S.No.</span></th>
                                            <th>
                                                <span className="userDatatable-title">User</span>
                                            </th>
                                            <th>
                                                <span className="checkbox-text userDatatable-title">
                                                    Order Code
                                                </span>
                                            </th>
                                            <th>
                                                <span className="checkbox-text userDatatable-title">
                                                    Product
                                                </span>
                                            </th>
                                            <th>
                                                <span className="checkbox-text userDatatable-title">
                                                    Amount
                                                </span>
                                            </th>
                                            <th>
                                                <span className="checkbox-text userDatatable-title">
                                                    Payment
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="userDatatable-content">1.</div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-content">
                                                    Eamil
                                                </div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-inline-title">
                                                    <h6>#222024</h6>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-inline-title">
                                                    <h6>product</h6>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-inline-title">
                                                    <h6>22024</h6>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="userDatatable-inline-title">
                                                    <h6>024</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end pt-30">
                                <nav className="dm-page ">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApprovedRefunds