import React, { useEffect, useState } from 'react'
import baseUrl from '../Helper/BaseUrl'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditSubCategory = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        category_id: "",
        name: "",
        status: "",
    })
    const [catList, setCatList] = useState([]);
    const [loading, setloading] = useState(false)
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: value,
        }))
    }

    // category view api
    const fetchSingleSubCategory = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/sub-category-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setValues(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("single sub category error", error)
        }
    }

    // category api
    const fetchCategory = async () => {
        try {
            const res = await axios.get(`${baseUrl}/category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log("rescat", res.data.data)
            setCatList(res.data.data)
        } catch (error) {
            console.log("fetch cat error", error)
        }
    }
    //edit sub category handle
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${baseUrl}/sub-category-update/${id}`, values, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (res.data.status) {
                toast.success(res.data.message)
                navigate('/subcategorylist')
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("edit sub cat error", error)
        }
    }
    useEffect(() => {
        fetchCategory();
        fetchSingleSubCategory();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/subcategorylist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    edit sub category
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                edit sub category
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Category name</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='category_id'
                                                                value={values?.category_id}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Category name</option>
                                                                {
                                                                    catList.map((cat, index) => {
                                                                        return (
                                                                            <option key={index} value={cat._id}>{cat.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">sub category name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='name'
                                                            value={values?.name}
                                                            onChange={handleChange}
                                                            placeholder="enter category name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">Status</label>
                                                            <select
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                                name='status'
                                                                value={values?.status}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option value="true">Active</option>
                                                                <option value="false">InActive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="button-group mt-1 add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                    <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                        save sub category
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditSubCategory