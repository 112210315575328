import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import baseUrl from '../Helper/BaseUrl'
import { toast } from 'react-toastify'

const EditVariantValue = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        variation_id: id,
        name: "",
        status: "",
    })
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preVal => ({
            ...preVal,
            [name]: value,
        }))
    }
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2VjNmE3MWI5YjNkNWFmYWU1ODRjZSIsIm1vYmlsZU51bWJlciI6ODk2NTk1MTE1MSwiZW1haWwiOiJtYW5pc2hAZ2FtaWwuY29tIiwiaWF0IjoxNzMyNjAxMjA2fQ.b7vfdebycvD7dNCAkTQXHOmHOiFvmXiTKaBZWf2jA1U'
    // variant api
    const fetchVariantsValue = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/variation-value-view/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log(res.data.data)
            setValues(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch variant error", error)
        }
    }

    const hanldeSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${baseUrl}/variation-value-update/${id}`, values, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log(res)
            if (res.data.status) {
                toast.success(res.data.message)
                navigate(`/variantvalueslist/${id}`)
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("error add variation", error)
        }
    }

    useEffect(() => {
        fetchVariantsValue();
    }, [])
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to={`/variantvalueslist/${id}`}>
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    Variation Value details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Variation Value details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-add global-shadow px-sm-30 py-sm-50 px-0 py-20 bg-white radius-xl w-100 mb-40">
                            <div className="row justify-content-center">
                                <div className="col-xxl-7 col-lg-10">
                                    <div className="mx-sm-30 mx-20 ">
                                        <div className="card add-product p-sm-30 p-20 mb-30">
                                            <div className="card-body p-0">
                                                <div className="card-header">
                                                    <h6 className="fw-500"> Variation Value details</h6>
                                                </div>
                                                <div className="add-product__body px-sm-40 px-20">
                                                    <form onSubmit={hanldeSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="name1">Variants value name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="name1"
                                                                name='name'
                                                                value={values.name}
                                                                onChange={handleChange}
                                                                placeholder="Type variation value name"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="countryOption">
                                                                <label htmlFor="countryOption">Variation value Status</label>
                                                                <select
                                                                    name='status'
                                                                    value={values.status}
                                                                    onChange={handleChange}
                                                                    className="js-example-basic-single js-states form-control"
                                                                    id="countryOption"
                                                                >
                                                                    <option value="">select status</option>

                                                                    <option value="true">Active</option>
                                                                    <option value="false">Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="button-group add-product-btn d-flex justify-content-sm-end justify-content-center mt-40">
                                                            <button type='submit' className="btn btn-primary btn-default btn-squared text-capitalize">
                                                                save Variants value
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditVariantValue




