import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import baseUrl from '../Helper/BaseUrl';

const UserListView = () => {
    const { id } = useParams();
    const [values, setValues] = useState({
        full_name: "",
        email: "",
        mobile: "",
        gender: "",
        country: "",
        state: "",
        city: "",
    });
    useEffect(() => {
        fetchUserList();
    }, [])
    const [loading, setloading] = useState(false)
    // user list api
    const fetchUserList = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/user-get/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setValues(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch user error", error)
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-breadcrumb">
                            <div className="breadcrumb-main">
                                <h4 className="text-capitalize breadcrumb-title">
                                    <li className="breadcrumb-item ">
                                        <Link to="/userlist">
                                            <i className="fa-solid fa-circle-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                    </li>
                                    / user Details
                                </h4>
                                <div className="breadcrumb-action justify-content-center flex-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">
                                                    <i className="uil uil-estate" />
                                                    Home
                                                </Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                user Details
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="add-product__body px-sm-40 px-20">
                                    <form>
                                        <div className='form-basic'>
                                            <div className='row'>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">user Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='full_name'
                                                            value={values.full_name}
                                                            disabled
                                                            placeholder="staff  "
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name2"> Email</label>
                                                        <input
                                                            type="text"
                                                            name='email'
                                                            value={values.email}
                                                            disabled
                                                            className="form-control"
                                                            id="name2"
                                                            placeholder="staff@gmail.com"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">mobile</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='mobile'
                                                            value={values.mobile}
                                                            disabled
                                                            placeholder="mobiles"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <div className="countryOption">
                                                            <label htmlFor="countryOption">gender</label>
                                                            <select
                                                                name='gender'
                                                                value={values.gender}
                                                                disabled
                                                                className="js-example-basic-single js-states form-control"
                                                                id="countryOption"
                                                            >
                                                                <option value="">Select gender</option>
                                                                <option value="male">male</option>
                                                                <option value="female">female</option>
                                                                <option value="other">other</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">country</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='country'
                                                            value={values.country}
                                                            disabled
                                                            placeholder="country"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">state</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='state'
                                                            value={values.state}
                                                            disabled
                                                            placeholder="state"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-4 mb-3'>
                                                    <div className="form-group">
                                                        <label htmlFor="name1">city</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="name1"
                                                            name='city'
                                                            value={values.city}
                                                            disabled
                                                            placeholder="city"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserListView