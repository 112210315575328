import React, { useState } from 'react'
import ekologo from '../../assets/img/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import baseUrl from '../Helper/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState('')
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const navigate = useNavigate();
    const validateForgotPass = () => {
        if (!email.trim()) {
            toast.error('Please enter email address');
            return false;
        }
        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address');
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForgotPass()) {
            // console.log("ssss",phoneno)
            setLoading(true)
            try {
                const res = await axios.post(`${baseUrl}/email-otp-send`, { email: email.toLowerCase().trim() }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                console.log(res)
                if (res.data.status) {
                    toast.success(res.data.message)
                    sessionStorage.setItem('resetEmail', email);
                    setEmail("")
                    navigate('/verify-otp');
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(res.data.message)
                }
            } catch (error) {
                setLoading(false)
                toast.error(error.response || 'Something went wrong');
                console.error("OTP send error:", error)
            }

        }

    }
    return (
        <>
            <main className="main-content login-sec">
                <div className="admin">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xxl-4 col-xl-4 col-md-6 col-sm-8">
                                <div className="edit-profile">
                                    <div className="edit-profile__logos">
                                        <img className="dark" src={ekologo} alt="logodark" />
                                        <img className="light" src={ekologo} alt="logolight" />
                                    </div>
                                    <div className="card border-0">
                                        <div className="card-header">
                                            <div className="edit-profile__title text-center">
                                                <h6>Forgot Password</h6>
                                                <p className='text-white'>Please provide your registered Email Address . Get started into</p>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className="edit-profile__body">
                                                    <div className="form-group">
                                                        <label htmlFor="username">
                                                            Email Address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            className="form-control"
                                                            id="username"
                                                            placeholder="enter your email"
                                                        />
                                                    </div>
                                                    <div className="admin__button-group button-group d-flex pt-1 justify-content-md-start justify-content-center mt-4">
                                                        <button type='submit' disabled={loading} className='btn btn-primary btn-default w-100 signIn-createBtn'>
                                                            {loading ? (
                                                                <span className="spinner-border spinner-border-sm"></span>
                                                            ) : (
                                                                "Get Otp"
                                                            )}
                                                            </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ForgotPassword