import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import { Link } from 'react-router-dom'
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
const UnitList = () => {
    const [unitList, setUnitList] = useState();
    const [loading, setloading] = useState(false)
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3M2VjNmE3MWI5YjNkNWFmYWU1ODRjZSIsIm1vYmlsZU51bWJlciI6ODk2NTk1MTE1MSwiZW1haWwiOiJtYW5pc2hAZ2FtaWwuY29tIiwiaWF0IjoxNzMyNjAxMjA2fQ.b7vfdebycvD7dNCAkTQXHOmHOiFvmXiTKaBZWf2jA1U'

    // get units api
    const fetchUnits = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/unit-list`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            // console.log(res)
            setUnitList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch unit list error", error)
        }
    }

    //unit status toggle
    const handleToggleStatus = async (unitId, currentStatus) => {
        try {
            const newStatus = !currentStatus;
            const res = await axios.post(`${baseUrl}/unit-status-change/${unitId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (res.data.status) {
                toast.success(res.data.message)
                setUnitList(prevList =>
                    prevList.map(unit =>
                        unit._id === unitId ? { ...unit, status: newStatus } : unit
                    )
                )
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("toggle unit status error", error)
        }

    }

    // delete api
    const handleUnitDelete = async (unitId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/unit-delete/${unitId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setUnitList(prevUnit => prevUnit.filter(unit => unit._id !== unitId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete unit', error)
                }
            }})
      
    }

    useEffect(() => {
        fetchUnits();
    }, [])

    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main user-member justify-content-sm-between ">
                            <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div className="d-flex align-items-center user-member__title justify-content-center me-sm-25">
                                    <h4 className="text-capitalize fw-500 breadcrumb-title">
                                        Units List{"   "}
                                    </h4>
                                    <span className="sub-title ms-sm-25 ps-sm-25 ms-2">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="uil uil-estate" />
                                                Home
                                            </Link>
                                        </li>
                                    </span>
                                </div>
                                <form
                                    action=""
                                    className="d-flex align-items-center user-member__form my-sm-0 my-2"
                                >
                                    <img
                                        src={searchImg}
                                        alt="search"
                                        className="svg"
                                    />
                                    <input
                                        className="form-control me-sm-2 border-0 box-shadow-none"
                                        type="search"
                                        placeholder="Search by Name"
                                        aria-label="Search"
                                    />
                                </form>
                            </div>
                            <div className="action-btn">
                                <Link to="/addunits" className="btn px-15 btn-primary">
                                    <i className="fas fa-plus fs-16" />
                                    Add Units
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                            <div className="table-responsive">
                                <table className="table mb-0 table-borderless">
                                    <thead>
                                        <tr className="userDatatable-header">
                                            <th>
                                                <span className="userDatatable-title">S.No.</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">Name</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title">Status</span>
                                            </th>
                                            <th>
                                                <span className="userDatatable-title float-end">
                                                    action
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            unitList && unitList.map((unit, index) => {
                                                return (
                                                    <tr key={unit?._id}>
                                                        <td>
                                                            <div className="userDatatable-content">
                                                                {index + 1}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content">
                                                                {unit?.unit_name}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="userDatatable-content d-inline-block">
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" checked={unit?.status} onChange={() => handleToggleStatus(unit?._id, unit?.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                <li>
                                                                    <Link to={`/unitview/${unit._id}`} className="view">
                                                                        <i className="uil uil-eye" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to={`/editunits/${unit._id}`} className="edit">
                                                                        <i className="uil uil-edit" />
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <a type="button" onClick={() => handleUnitDelete(unit._id)} className="remove">
                                                                        <i className="uil uil-trash-alt" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end pt-30 mt-3 mb-3">
                                <nav className="dm-page ">
                                    <ul className="dm-pagination d-flex">
                                        <li className="dm-pagination__item">
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-left" />
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">1</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link active">
                                                <span className="page-number">2</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">3</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="page-number">...</span>
                                            </a>
                                            <a href="#" className="dm-pagination__link">
                                                <span className="page-number">12</span>
                                            </a>
                                            <a
                                                href="#"
                                                className="dm-pagination__link pagination-control"
                                            >
                                                <span className="la la-angle-right" />
                                            </a>
                                            <a href="#" className="dm-pagination__option"></a>
                                        </li>
                                        <li className="dm-pagination__item">
                                            <div className="paging-option">
                                                <select name="page-number" className="page-selection">
                                                    <option value={20}>20/page</option>
                                                    <option value={40}>40/page</option>
                                                    <option value={60}>60/page</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnitList