import React, { useEffect, useState } from 'react'
import searchImg from "../../assets/img/svg/search.svg"
import tm6 from "../../assets/img/tm6.png"
import { Link } from 'react-router-dom'
import axios from 'axios'
import baseUrl, { imgUrl } from '../Helper/BaseUrl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

const SubCategoryList = () => {
    const [subcatList, setSubCatList] = useState([]);
    const [loading, setloading] = useState(false)

    useEffect(() => {
        fetchSubCategory();
    }, [])

    // fetch sub category
    const fetchSubCategory = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${baseUrl}/sub-category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("rescat", res.data.data)
            setSubCatList(res.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log("fetch sub cat error", error)
        }
    }
    // delete api
    const handleSubCatDelete = async (catId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/sub-category-delete/${catId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (res.data.status) {
                        toast.success(res.data.message)
                        setSubCatList(prevSubCat => prevSubCat.filter(sub => sub._id !== catId));
                    } else {
                        toast.error(res.data.message)
                    }

                } catch (error) {
                    console.log('error delete subcategory', error)
                }
            }
        })

    }
    // handle sub cat status
    const handleToggleStatus = async (catId, currentStatus) => {
        try {
            setloading(true)
            const newStatus = !currentStatus;
            const res = await axios.post(`${baseUrl}/sub-category-status-change/${catId}`,
                { status: newStatus },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
            console.log(res);
            if (res.data.status) {
                toast.success(res.data.message)
                setloading(false)
                setSubCatList(prevCat =>
                    prevCat.map(cat =>
                        cat._id === catId ? { ...cat, status: newStatus } : cat
                    )
                )
            }
        } catch (error) {
            setloading(false)
            console.log("toggle sub category status error", error)
        }
    }
    return (
        <>
            {
                loading && (
                    <div id="overlayer">
                        <div className="loader-overlay">
                            <div className="dm-spin-dots spin-lg">
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                                <span className="spin-dot badge-dot dot-primary"></span>
                            </div>
                        </div>
                    </div>
                )}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-main">
                            <h4 className="text-capitalize breadcrumb-title">Sub Category list</h4>
                            <div className="breadcrumb-action justify-content-center flex-wrap">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"><i className="uil uil-estate"></i>Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Sub Category list</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <form
                                    action=""
                                    className="user-member__form"
                                >

                                    <div className='with-icon'>
                                        <span className="uil uil-search"></span>
                                        <input
                                            className="form-control"
                                            type="search"
                                            placeholder="Search by Name"
                                            aria-label="Search"
                                            // onChange={(e) => setSearchText(e.target.value)}
                                        />
                                    </div>
                                </form>
                                <div>
                                    <Link className="btn btn-primary" to="/addsubcategory"><span className="uil uil-plus"></span>Add Sub Category</Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="userDatatable global-shadow border-light-0 p-30 bg-white radius-xl w-100 mb-30">
                                    <div className="table-responsive">
                                        <table className="table mb-0 table-borderless">
                                            <thead>
                                                <tr className="userDatatable-header">
                                                    <th><span className="userDatatable-title">S.No.</span></th>
                                                    <th>
                                                        <span className="checkbox-text userDatatable-title">
                                                            Sub Category Name
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title">status</span>
                                                    </th>
                                                    <th>
                                                        <span className="userDatatable-title float-end">
                                                            action
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    subcatList.map((cat, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="userDatatable-content">{index + 1}</div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-inline-title">
                                                                        <h6>{cat.name}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="userDatatable-content d-inline-block">
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" checked={cat.status} onChange={() => handleToggleStatus(cat._id, cat.status)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                                                                        <li>
                                                                            <Link to={`/subcategoryview/${cat._id}`} className="view">
                                                                                <i className="uil uil-eye" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to={`/editsubcategory/${cat._id}`} className="edit">
                                                                                <i className="uil uil-edit" />
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <a type='button' onClick={() => handleSubCatDelete(cat._id)} className="remove">
                                                                                <i className="uil uil-trash-alt" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="d-flex justify-content-end pt-30">
                              <nav className="dm-page ">
                                  <ul className="dm-pagination d-flex">
                                      <li className="dm-pagination__item">
                                          <a
                                              href="#"
                                              className="dm-pagination__link pagination-control"
                                          >
                                              <span className="la la-angle-left" />
                                          </a>
                                          <a href="#" className="dm-pagination__link">
                                              <span className="page-number">1</span>
                                          </a>
                                          <a href="#" className="dm-pagination__link active">
                                              <span className="page-number">2</span>
                                          </a>
                                          <a href="#" className="dm-pagination__link">
                                              <span className="page-number">3</span>
                                          </a>
                                          <a
                                              href="#"
                                              className="dm-pagination__link pagination-control"
                                          >
                                              <span className="page-number">...</span>
                                          </a>
                                          <a href="#" className="dm-pagination__link">
                                              <span className="page-number">12</span>
                                          </a>
                                          <a
                                              href="#"
                                              className="dm-pagination__link pagination-control"
                                          >
                                              <span className="la la-angle-right" />
                                          </a>
                                          <a href="#" className="dm-pagination__option"></a>
                                      </li>
                                      <li className="dm-pagination__item">
                                          <div className="paging-option">
                                              <select name="page-number" className="page-selection">
                                                  <option value={20}>20/page</option>
                                                  <option value={40}>40/page</option>
                                                  <option value={60}>60/page</option>
                                              </select>
                                          </div>
                                      </li>
                                  </ul>
                              </nav>
                          </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubCategoryList