import React from 'react'

const Footer = ({isVisible}) => {
    return (
        <>
            <footer className={`footer-wrapper ${isVisible ? 'expanded' : ''}`}>
                <div className="footer-wrapper__inside">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer-copyright">
                                    <p>
                                        <span>© 2024</span>
                                        <a href="#">Sweetz n'snackz</a>
                                    </p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer